import axios from './axios.interceptors';
import {Reservations} from "../types/service-types/ReservationsTypes";
import qs from "querystring";

class ReservationsServices {
    public getReservations = async (): Promise<Reservations[]> => {
        const accessToken = localStorage.getItem("pr_jet_access_token");
        try {
            const response = await axios.get('/api/v1/reservations',
                { headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }
}

export default new ReservationsServices();