import React, {useState} from "react";
import './AuthenticationDrawer.scss';
import {useDispatch, useSelector} from "react-redux";
import {Drawer} from "antd";
import LoginDrawerContent from "./LoginDrawerContent";
import RegisterDrawerContent from "./RegisterDrawerContent";
import {AppState} from "../../store";
import {setDrawerContent} from "../../store/auth";
import ForgotPasswordContent from './ForgotPasswordContent'


interface Props {
    onClose: any;
    isDrawerOpen: boolean;
}


enum AuthenticationDrawerContent {
    Login = "login",
    Register = "register",
    ForgotPassword ='forgot-password'
}

const AuthenticationDrawer = ({isDrawerOpen, onClose}: Props) => {

    const dispatch = useDispatch();
    const drawerContent = useSelector((state: AppState) => state.auth.drawerContent);

    const openLoginDrawer = () => {
        dispatch(setDrawerContent(AuthenticationDrawerContent.Login));
    }

    const openRegisterDrawer = () => {
        dispatch(setDrawerContent(AuthenticationDrawerContent.Register));
    }

    const openForgotPasswordDrawer = () => {
        dispatch(setDrawerContent(AuthenticationDrawerContent.ForgotPassword));
    }

    const isMobile = window.innerWidth <= 992;

    return (
        <Drawer className="authentication-drawer" placement="right" closable={true} onClose={onClose} width={isMobile ? "100%" : "50%"} visible={isDrawerOpen}>
            {drawerContent === AuthenticationDrawerContent.Login &&
            <LoginDrawerContent
                onClickRegister={openRegisterDrawer}
                onClickForgotPassword={openForgotPasswordDrawer}
                onClose={onClose} />}

            {drawerContent === AuthenticationDrawerContent.ForgotPassword &&
            <ForgotPasswordContent
                onClickLogin={openLoginDrawer}
                onClose={onClose} />}

            {drawerContent === AuthenticationDrawerContent.Register &&
            <RegisterDrawerContent
                onClickLogin={openLoginDrawer}
                onClickForgotPassword={openForgotPasswordDrawer}
                onClose={onClose} />}
        </Drawer>
    )
}

export default AuthenticationDrawer;
