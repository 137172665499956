import React, {useEffect, useState} from "react";
import MyBooking from "../../components/MyBooking/MyBooking";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {fetchAllReservations} from "../../store/reservations";
import {SearchOutlined} from '@ant-design/icons';
import {Input, Select, Tabs} from 'antd';
import {useHistory} from "react-router";
import './Bookings.scss';
import SideOffers from "../../components/SideOffers";
import NoBookings from "../../components/NoBookings/NoBookings";
import Spinner from "../../components/Spinner/Spinner";
import moment from "moment";

const {TabPane} = Tabs;
const {Option} = Select;

const Bookings = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const [confirmationCode, setConfirmationCode] = useState<any>("");

  const reservations = useSelector((state: AppState) => state.reservations.reservations);
  const reservationsFetched = useSelector((state: AppState) => state.reservations.reservationsFetched);

  const filteredReservations: any = reservations
  .flatMap((r: any) => r.reservations).filter((reservation: any) => reservation.confirmationCode.toString().includes(confirmationCode)) || [];

  useEffect(() => {
    dispatch(fetchAllReservations());
  }, [])


  const handleConfirmationCodeInputChange = (e: any) => {
    setConfirmationCode(e.target.value);
  }

  const handleReservationClick = (reservation: any) => {
    history.push("/bookings/" + reservation.id);
  }


  const formatDate = (date: any) => {
    let parts = date.split("/");
    return new Date(parts[2], parts[1] - 1, parts[0]);
  }

  const bookings = (tab: string) => {
    const today = moment(new Date()).format('DD/MM/YYYY');
    const allReservations =
        filteredReservations
        .filter((reservation: any) => {
          const traveler = reservation?.travelers[0] as any;
          const departureFlightDate = traveler?.departureFlight?.dateOfDeparture;
          const returningFlightDate = traveler?.returningFlight?.dateOfDeparture || '';
          const upComingCondition = formatDate(departureFlightDate) >= formatDate(today)
              || formatDate(returningFlightDate) >= formatDate(today);
          const pastCondition =
              formatDate(departureFlightDate) < formatDate(today)
              || formatDate(returningFlightDate) < formatDate(today);

          return tab === 'upcoming' ? upComingCondition : pastCondition;
        })
    if (!allReservations.length) {
      return (<div className="bookings-center">
        {reservationsFetched ? <NoBookings/> : <Spinner/>}
      </div>)
    }

    if (!!allReservations.length) {
      return allReservations.map((reservation: any) =>
          <MyBooking key={reservation.id} reservation={reservation}
                     onClick={() => handleReservationClick(reservation)}/>)
    }

  }

  return (
      <div className="bookings-container">
        <div className="row">
          <div className="col-md-12 col-lg-9">

            <div className="d-flex align-items-center mb-5">
              <div className="bookings-title">
                All My Bookings
              </div>
              <div className="w-50 ml-1 ml-md-5">
                <Input
                    size="small"
                    placeholder="Search with confirmation code"
                    value={confirmationCode}
                    prefix={<SearchOutlined/>}
                    onChange={handleConfirmationCodeInputChange}/>
              </div>
            </div>
            <Tabs defaultActiveKey="1">
              <TabPane className="p-3" tab="Upcoming bookings" key="1">
                {bookings('upcoming')}
              </TabPane>
              <TabPane className="p-3" tab="Past bookings" key="2">
                {bookings('past')}
              </TabPane>
            </Tabs>

          </div>

          <div className="col-md-12 col-lg-3 right-column">
            <SideOffers/>
          </div>
        </div>
      </div>
  )
}

export default Bookings;
