import React from 'react';
import styles from "./NoBookings.module.scss";
import {CalendarOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

interface Props {

}

const NoBookings = (props: Props) => {
    const {t} = useTranslation();
    const history = useHistory();
    return (
        <div className={styles.noBookings}>
            <CalendarOutlined/>
            <p>{t("app.myBookings.noBookings.text", "No bookings yet")}</p>
            <Button
                htmlType="button"
                onClick={() => history.push("/")}
                className="button-primary mb-3">
                {t("app.myBookings.noBookings.button", "Book now")}
            </Button>
        </div>
    )
}

export default NoBookings;
