import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => {
        if (process.env.NODE_ENV === 'development') {
            const { logger } = require(`redux-logger`);

            return getDefaultMiddleware().concat(logger);
        }

        return getDefaultMiddleware();
    },
    devTools: process.env.NODE_ENV === 'development'
});

export type AppState = ReturnType<typeof rootReducer>;

export default store;