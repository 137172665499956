export interface ManualPayment {
  country?: string;
  name?: string;
  recipient?: string;
  bicSwift?: string;
  IBAN?: string;
  address?: string;
  description?: string;
}

export const ManualPayments: ManualPayment[] = [
  {
    country: 'Germany:',
    name: 'SEPA Transfer',
    recipient: 'PrishtinaJET',
    bicSwift: 'SOBKDEB2XXX',
    IBAN: 'DE33 1101 0101 5415 0341 14',
  }, {
    country: 'Switzerland:',
    name: 'PostFinance',
    recipient: 'PrishtinaJET UG',
    bicSwift: 'POFICHBEXXX',
    IBAN: 'CH85 0900 0000 1593 3877 0',
    address: 'DE-40468 Dusseldorf',
  }, {
    country: 'Kosovo',
    name: 'NLB Banka Prishtinë',
    recipient: 'Prishtina Jet',
    bicSwift: 'NLPRXKPR',
    IBAN: '1708 0176 0017 0684',
  }
]
