import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {SelectedFlightService} from "../actions";

export const fetchDailyFlights = (body: any) => async (dispatch: Dispatch) => {
    setLoading(true);
    const response = await SelectedFlightService.getSelectedFlight(body);
    dispatch(dailyFlights({...response, isReturn: body.isReturn}));
    setLoading(false);
}

export const setDailyFlights = (body: any) => async (dispatch: Dispatch) => {
    dispatch(dailyFlights(body));
}

interface SelectedFlightSlice {
    dailyFlights: Array<any>;
    arrival: any;
    departure: any;
    loading: boolean;
}

const initialState: SelectedFlightSlice = {
    dailyFlights: [],
    arrival: {},
    departure: {},
    loading: false
}

const stateSlice = createSlice({
    name: 'bookflight/selected-flight',
    initialState,
    reducers: {
        setLoading: (state, action) => {state.loading = action.payload},
        dailyFlights: (state, action) => {
            state.dailyFlights = action.payload.flights
            if(action.payload.isReturn) {
                state.arrival = action.payload.departureAirport
                state.departure = action.payload.arrivalAirport
            } else {
                state.arrival = action.payload.arrivalAirport
                state.departure = action.payload.departureAirport
            }
        }
    }
});

export const {setLoading, dailyFlights} = stateSlice.actions;

export default stateSlice.reducer;
