import React from "react";
import styles from './Details.module.scss';
import AirplaneIcon from "../../assets/images/airplane.png";
import QrCode from "../../assets/images/qr-code.png";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {PassengerType} from "../PassengerFormRow/PassengerFormRow";

interface Props {
    passenger: any;
}

const Passenger = ({passenger}: Props) => {
    const {t} = useTranslation();

    const type = passenger.ageCategory;
    const PassengerTitle: any = {
        [PassengerType.ADULT]: t("app.bookFlight.index.adult", "ADULT"),
        [PassengerType.CHILD]: t("app.bookFlight.index.child", "CHILD"),
        [PassengerType.INFANT]: t("app.bookFlight.index.baby", "INFANT")
    }


    return (
        <div className="passengers-info">
            <div>
                <p style={{fontSize: '14px'}}>{t("app.bookFlight.overview.bookingInformation", "Passenger")} - {PassengerTitle[type]} </p>
                <div className="rowStyle">
                    <div className="colStyle">
                        <label htmlFor="nameInput" className="passengerLabel">{t("app.bookFlight.overview.bookingInformation.name", "NAME")}</label>
                        <div className="data">{passenger.name}</div>
                    </div>
                    <div className="colStyle">
                        <label htmlFor="surnameInput" className="passengerLabel">{t("app.bookFlight.overview.bookingInformation.surname", "SURNAME")}</label>
                        <div className="data">{passenger.surname}</div>
                    </div>
                    <div className="colStyle">
                        <label htmlFor="birthdayInput" className="passengerLabel">{t("app.bookFlight.overview.bookingInformation.gender", "GENDER")}</label>
                        <div className="data">{passenger.gender}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Passenger;
