import React from 'react'
import styles from "./FlightBox.module.scss";
import moment from "moment";
import classNames from "classnames";

interface Props {
    flight: any;
    className?: string;
    selected?: boolean;
    disabled?: boolean;
    onClick: any;
}

const FlightBox = (props: Props) => {

    const {flight, selected, disabled, className, onClick} = props;

    const formatPrice = () => {
        let price = flight.lowestPrice.split(".", 1);
        let symbol = flight.lowestPrice.split(".00", 2)[1];
        return price + " " + symbol;
    }

    const classes = classNames(styles.flightBox, className, {
        [styles.disabled]: disabled,
        [styles.selected]: selected,
    });

    return (
        <div className={classes}
             onClick={onClick}>
            <div className={styles.day}>{moment(flight.dateOfDeparture, "dddd DD MMMM").format("dddd")}</div>
            <div className={styles.date}>{moment(flight.dateOfDeparture, "DD MMMM").format("DD MMMM")}</div>
            <div className={styles.price}>{flight.lowestPrice ?  formatPrice() : "No flight"}</div>
        </div>
    )
}

export default FlightBox;
