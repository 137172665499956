import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Wing from "../../assets/images/plane-wing.png";
import ArrDep from "../../assets/images/dep-arr.png";
import Inside from "../../assets/images/plane-inside.png";


const About = () =>{


    const scrollToWhoAreWe = () => {
        window.scrollTo({
            top: 270,
            behavior: "smooth"
        });
    }

    const scrollToDestinations = () => {
        window.scrollTo({
            top: 810,
            behavior: "smooth"
        });
    }

    return(
        <div>
            <div className="about-us">
                <h2>About Us</h2>
                {/*<p>Nunc commodo porttitor ipsum sit amet venenatis. Aliquam libero quam, blandit ac placerat eget,*/}
                {/*    laoreet vitae quam. Aliquam ut nunc vel sem consectetur pellentesque.*/}
                {/*    Pellentesque mollis efficitur euismod.</p>*/}
                <div className="about-position">
                    <div className="content">
                        <p onClick={scrollToWhoAreWe}>Who are we?</p>
                        <p onClick={scrollToDestinations}>Destinations</p>
                    </div>
                    <div className="about-us-content">
                        <h3>Who are we?</h3>
                        <p> PrishtinaJet was founded by a large group of investors, aviation professionals and
                            multi-year workers from the tourism and travel industry and from the beginning is a close
                            cooperation with the very serious airline. For flights in the nearby region and
                            destinations in Europe we will be using Airbus A320 aircraft with 180 seats and Airbus A321 with 220 seats.</p>
                        <img src={Wing}/>
                        <h3>Destinations</h3>
                        <p> The first flights offererd will be: </p>
                        <div className="destinations">
                            <b>• Pristina - Basel - Pristina</b> direct flights (every day in the morning and in the afternoon up to 12 per week) <br/>
                            <b>• Pristina - Düsseldorf - Pristina</b> direct flights (every day) <br/>
                            <b>• Pristina - Stuttgart - Pristina</b> direct flights (every day) <br/>
                            <b>• Pristina - Munich - Pristina</b> direct flights (up to 5 times a week)
                        </div>
                        <img src={ArrDep}/>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    )
}

export default About;
