import styles from "./Print.module.css";
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {AppState} from "../../store";

const PrintInvoiceSharedInfo = () => {
  const numbers = useSelector((state: AppState) => state?.phoneNumber);

  useEffect(() => {
    console.log('numbers', numbers)
  }, [])

  const countryNameTransform = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
      <>
        <div style={{marginTop: '10px'}}>
          <p>Bitte finden Sie sich spätestens 2 Stunden vor Abflug zum Check-in am Flughafen
            ein.</p>
          <p>Për shërbime të Check-in ju lutem të jeni në aeroport dy orë para fluturimt.</p>
        </div>
        <div className={`${styles.importantInfo} ${styles.marginTop50}`}>
          <p>Wichtiger Hinwels : Bitte Lassen Sie Spätestens 48 Stunden vor Abflug Ihren Flug
            Rückbestätigen!</p>
          <p>Informatë e rëndësishme : Ju lutemi të konfirmoni fluturimin tuaj 48 orë para
            nisjes.</p>
        </div>
        <div className={`${styles.flex} ${styles.marginTop50} ${styles.banks}`}>
          <div>
            {numbers.phoneNumbers?.map((number: any) => {
              return (
                  <p>{countryNameTransform(number.countryName)}: {number.phoneNumber}</p>
              );
            })}
          </div>
          <div>
            <p>Bank Germany:</p>
            <p>SEPA Transfer</p>
            <p>IBAN: DE33 1101 0101 5415 0341 14</p>
            <p>BIC: SOBKDEB2XXX</p>
            <p>Vollstandiger Name: PrishtinaJET</p>
          </div>
          <div>
            <p>PostFinance Switzerland:</p>
            <p>Konto nummber: 15-933877-0</p>
            <p>IBAN: CH85 0900 0000 1593 3877 0</p>
            <p>BIC: POFICHBEXXX</p>
            <p>PrishtinaJET UG</p>
            <p>DE-40468 Dusseldorf</p>
          </div>
          <div>
            <p>Bank Kosovë:</p>
            <p>Banka: NLB Banka Prishtinë</p>
            <p>Llogaria: 1708 0176 0017 0684</p>
            <p>SWIFT: NLPRXKPR</p>
            <p>Perfituesi: Prishtina Jet</p>
          </div>
        </div>
      </>
  )
}

export default PrintInvoiceSharedInfo;
