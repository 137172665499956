import axios from './axios.interceptors';
import {PhoneNumberTypes} from "../types/service-types/PhoneNumberTypes";

class PhoneNumberService {
  fetchNumbers = async (): Promise<PhoneNumberTypes[]> => {
    try {
      const response = await axios.get('/api/v1/public-phone-numbers');
      return response.data;
    } catch (err) {
      throw err;
    }
  }
}

export default new PhoneNumberService();