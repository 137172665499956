import {DatePicker, Radio, Space} from 'antd';
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState, useMemo} from 'react';
import styles from "./PassengerFormRow.module.scss";
import moment from "moment";
import classNames from "classnames";
import {useTranslation} from "react-i18next";

interface Props {
  type: PassengerType;
  passenger: any;
  onChange: any;
  isValidationVisible: boolean;
}

export enum PassengerType {
  ADULT = "ADULT",
  CHILD = "CHILD",
  INFANT = "INF"
}


const PassengerFormRow = forwardRef(({type, index, passenger, onChange, isValidationVisible}: any,ref) => {

  const {t} = useTranslation();

  const [dateNotValid, setDateValid] = useState(false);

  useEffect(() => {
    setDateValid(disabledDates(passenger.dateOfBirth));
  }, []);


  const handleChange = (name: any, value: any) => {
    let newPassenger = {
      ...passenger,
      [name]: value
    };
    onChange(newPassenger);
    if (name === 'dateOfBirth') {
      console.log('YEARS:',moment().diff(value, 'years'));
      setDateValid(disabledDates(value))
    }

  }

  const PassengerTitle: any = {
    [PassengerType.ADULT]: t("app.bookFlight.passengers.passengerRow.adult", "Adult"),
    [PassengerType.CHILD]: t("app.bookFlight.passengers.passengerRow.child", "Child"),
    [PassengerType.INFANT]: t("app.bookFlight.passengers.passengerRow.baby", "Infant")
  }


  const disabledDates = (date: any) => {
    const current = moment(date, "DD/MM/YYYY");
   const years =  moment().diff(current, "years");
    if (type === PassengerType.ADULT) {
      if (years < 15) {
        return true
      }
    } else if (type === PassengerType.CHILD) {
      if (years < 2 || years > 14 ) {
        return true
      }
    } else if (type === PassengerType.INFANT) {
      if (years > 1 ||  current.isAfter(moment())) {
        return true
      }
    }

    return false;
  }

  const [nameError, setNameError] = useState<string>('');
  const [surnameError, setSurnameError] = useState<string>('');
  const genderValidation = (!passenger?.gender?.trim()) && isValidationVisible;
  const nameValidation = (!passenger?.name?.trim()) && isValidationVisible;
  const surnameValidation = (!passenger?.surname?.trim()) && isValidationVisible;
  const birthdayValidation = (!passenger?.dateOfBirth) && isValidationVisible;

  const re = useMemo(() => {
    return new RegExp("^[A-Za-z]+$");
  }, [])

  const validateName = (e: any) => {
    setNameError('');
    if (!e.target.value) {
     return (nameValidation);
    } else if (!re.test(e.target.value)) {
     setNameError("Only alphabets are allowed");
    }
  }

  const validateSurname = (e: any) => {
    setSurnameError('');
    if (!e.target.value) {
      return (surnameValidation);
    } else if (!re.test(e.target.value)) {
      setSurnameError("Only alphabets are allowed");
    }
  }


  const defaultPickerValue = () => {
    if (type == PassengerType.ADULT) {
      return moment().subtract(15, "years");
    } else if (type == PassengerType.CHILD) {
      return moment().subtract(2, "years");
    } else {
      return moment()
    }
  }



  useImperativeHandle(ref, () => ({

    dateNotValid(){
       return dateNotValid;
     }
  }));

  const KeyboardInputDate = (e: any) => {
    if(e.which !== 8) {
      const numChars = e.target.value.length;
      if(numChars === 2 || numChars === 5) {
        let thisVal = e.target.value;
        thisVal += '/';
        e.target.value = thisVal;
      }
    }
  }

  return (
      <div>
        <div className={styles.passengerFormRowTitle}>
          {index} {PassengerTitle[type]} {t("app.bookFlight.passengers.passengerRow.passenger", "Passenger Details")}
        </div>

        <div className={styles.row}>
          <input
              placeholder="FIRST NAME"
              type="text"
              id="nameInput"
              value={passenger?.name}
              className={styles.passengerFormRowInput}
              onChange={(e) => {handleChange("name", e.target.value);
              validateName(e);}}
          />
           {nameError && (<span className={styles.validationMessage}>{nameError}</span>) || nameValidation && <span className={styles.validationMessage}>{t("app.validations.required", "Required")}</span> }
          <input
              placeholder="LAST NAME"
              type="text"
              id="surnameInput"
              value={passenger?.surname}
              className={styles.passengerFormRowInput}
              onChange={(e) => {handleChange("surname", e.target.value);
              validateSurname(e);}}
          />
          {surnameError && (<span className={styles.validationMessage}>{surnameError}</span>) || surnameValidation &&
              <span className={styles.validationMessage}>{t("app.validations.required", "Required")}</span>}
            </div>

        <div className={styles.birthdayInfo}>
          <Space direction="horizontal" className={styles.space}>
            <DatePicker className={styles.day} onChange={onChange} placeholder="Day"/>
            <DatePicker className={styles.month} onChange={onChange} placeholder="Month" picker="month" />
            <DatePicker className={styles.year} onChange={onChange} placeholder="Year" picker="year" />
          </Space>

          <div className={classNames(styles.radioButtons, {[styles.hasError]: genderValidation})}>
            <Radio.Group className="mb-3" value={passenger.gender}
                         onChange={(e) => handleChange("gender", e.target.value)}>
              <Radio
                  value="FEMALE">{t("app.bookFlight.passengers.passengerRow.female", "Female")}</Radio>
              <Radio value="MALE">{t("app.bookFlight.passengers.passengerRow.male", "Male")}</Radio>
            </Radio.Group>
            {genderValidation && <span
                className={styles.validationMessage}>{t("app.validations.required", "Required")}</span>}
          </div>

        </div>
        {/*<div className={styles.row}>*/}
        {/*  <div className={classNames(styles.col, {[styles.hasError]: nameValidation})}>*/}
        {/*    <label htmlFor="nameInput"*/}
        {/*           className={styles.passengerFormRowLabel}>{t("app.bookFlight.passengers.passengerRow.name.label", "NAME")}</label>*/}
        {/*    <input*/}
        {/*        placeholder={t("app.home.form.passengers.label.name", "Write here")}*/}
        {/*        type="text"*/}
        {/*        id="nameInput"*/}
        {/*        value={passenger.name}*/}
        {/*        className={styles.passengerFormRowInput}*/}
        {/*        onChange={(e) => {handleChange("name", e.target.value);*/}
        {/*          validateName(e);}}/>*/}
        {/*    {nameError && (<span className={styles.validationMessage}>{nameError}</span>) || nameValidation && <span className={styles.validationMessage}>{t("app.validations.required", "Required")}</span> }*/}
        {/*  </div>*/}
        {/*  <div className={classNames(styles.col, {[styles.hasError]: surnameValidation})}>*/}
        {/*    <label htmlFor="surnameInput"*/}
        {/*           className={styles.passengerFormRowLabel}>{t("app.bookFlight.passengers.passengerRow.surname.label", "SURNAME")}</label>*/}
        {/*    <input*/}
        {/*        placeholder={t("app.home.form.passengers.label.surname", "Write here")}*/}
        {/*        type="text"*/}
        {/*        id="surnameInput"*/}
        {/*        value={passenger.surname}*/}
        {/*        className={styles.passengerFormRowInput}*/}
        {/*        onChange={(e) => {handleChange("surname", e.target.value);*/}
        {/*    validateSurname(e);}}/>*/}
        {/*    {surnameError && (<span className={styles.validationMessage}>{surnameError}</span>) || surnameValidation && <span*/}
        {/*        className={styles.validationMessage}>{t("app.validations.required", "Required")}</span>}*/}
        {/*  </div>*/}
        {/*  <div className={classNames(styles.col, {[styles.hasError]: birthdayValidation ||dateNotValid })}>*/}
        {/*    <label htmlFor="birthdayInput"*/}
        {/*           className={styles.passengerFormRowLabel}>{t("app.bookFlight.passengers.passengerRow.birthday.label", "BIRTHDAY")}</label>*/}
        {/*    <DatePicker*/}
        {/*        className="passengerFormRowDatePicker"*/}
        {/*        defaultPickerValue={defaultPickerValue()}*/}
        {/*        placeholder="dd/mm/yyyy"*/}
        {/*        id="birthdayInput"*/}
        {/*        format="DD/MM/YYYY"*/}
        {/*        value={passenger.dateOfBirth ? moment(passenger.dateOfBirth, "DD/MM/YYYY") : null}*/}
        {/*        onChange={(date) => handleChange("dateOfBirth", date?.format("DD/MM/YYYY"))}*/}
        {/*        onKeyDown={KeyboardInputDate}*/}
        {/*        inputReadOnly*/}
        {/*    />*/}
        {/*    {birthdayValidation && <span*/}
        {/*        className={styles.validationMessage}>{t("app.validations.required", "Required")}</span>}*/}
        {/*    {(dateNotValid && !birthdayValidation ) &&*/}
        {/*        <span className={styles.validationMessage}>Date is not valid </span>}*/}
        {/*  </div>*/}
        {/*</div>*/}

      </div>
  )
})

export default PassengerFormRow;
