import { createSlice, Dispatch } from "@reduxjs/toolkit";
import { CustomersService } from "../actions";
import { AccountInfoBody } from "../types/service-types/CustomerTypes";

export const fetchAccountDetails = () => async (dispatch: Dispatch) => {
  const response = await CustomersService.getAccountInfo();
  dispatch(setAccountInfo(response));
  return response;
};

interface AccountInfoSlice {
  accountInfo: AccountInfoBody;
}

const initialState: AccountInfoSlice = {
  accountInfo: {
    fullName: "",
    email: "",
    phoneNumber: "",
  },
};

const stateSlice = createSlice({
  name: "myaccount/account-info",
  initialState,
  reducers: {
    setAccountInfo: (state, action) => {
      state.accountInfo = action.payload;
    },
    accountHandleChange: (state, action) => {
      const { name, value } = action.payload;
      // @ts-ignore
      state.accountInfo[name] = value;
    },
  },
});

export const { setAccountInfo, accountHandleChange } = stateSlice.actions;

export default stateSlice.reducer;
