import React from "react";
import "./myBooking.scss";
import { CalendarOutlined } from "@ant-design/icons/lib";

interface Props {
  reservation: any;
  onClick: any;
}

const MyBooking = (props: Props) => {
  const { reservation, onClick } = props;

  const traveler = reservation.travelers[0];
  const returningFlight = traveler.returningFlight;
  return (
    <div className="booking" onClick={onClick}>
      <div className="booking-flight">
        <div style={{ display: "flex" }}>
          <CalendarOutlined />
          <div style={{display: 'flex'}}>
              <div>
                  <b>Departure: </b>
                  {traveler?.departureFlight.dateOfDeparture} - {traveler?.departureFlight.timeOfDeparture} {" "}
                  {traveler?.departureFlight.dateOfArrival} - {traveler?.departureFlight.timeOfArrival}
              </div>
              <div style={{marginLeft: '2rem'}}>

                  {returningFlight ? (<>
                      <b>Return: </b>
                      {returningFlight?.dateOfDeparture} - {returningFlight?.timeOfDeparture} {" "}
                {returningFlight?.dateOfArrival} - {returningFlight?.timeOfArrival}</>
                  ) : (
                      <></>
                  )}
              </div>
          </div>
        </div>
        <h2>
          {traveler?.departureFlight.departureAirport} -
          {traveler?.departureFlight.arrivalAirport}
          {returningFlight ? `- ${returningFlight?.arrivalAirport}` : ''}
        </h2>
      </div>
      <div className="confirmation-code">
        Confirmation Code<span>{reservation.confirmationCode}</span>
      </div>
    </div>
  );
};

export default MyBooking;
