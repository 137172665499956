import React, {useState} from "react";
import "./ManualPaymentsComponent.scss";
import ManualPaymentCard from "./ManualPaymentCard/ManualPaymentCard";
import PrintPaymentMethod from "./printPayment/PrintPaymentMethod";
import {PrinterOutlined} from "@ant-design/icons";
import {ManualPayment, ManualPayments} from "./ManualPayments";

const ManualPaymentsComponent = (props: any) => {

  const manualPayments: ManualPayment[] = ManualPayments;

  const [showPrintPayment, setShowPrintPayment] = useState(false);
  const [payment, setPayment] = useState<ManualPayment>({});

  const showPrint = (value: boolean) => {
    setShowPrintPayment(value)
  }

  const printButton = (payment: ManualPayment) => {
    return (<PrinterOutlined
            style={{fontSize: "2rem"}}
            onClick={() => {
              setPayment(payment);
              setShowPrintPayment(true);
            }}/>
    )
  }

  return (
      <>
        <div style={{display: "none"}}>
          {showPrintPayment && (<PrintPaymentMethod payment={payment} showPrint={showPrint}
                                                    confirmationCode={props.confirmationCode}
                                                    debt={props.debt}/>)}
        </div>

        <div className="manual-payments-container">
          <h2 className="mb3">Please transfer to the following account from the bank in
            your country.</h2>
          <div className="payments-wrapper">
            {manualPayments.map(payment => {
              return (<ManualPaymentCard payment={payment} printButton={printButton(payment)}
                                         confirmationCode={props.confirmationCode}
                                         debt={props.debt}/>)
            })}
          </div>
        </div>
      </>
  )
}

export default ManualPaymentsComponent;
