import React, {useEffect} from "react";
import './Account.scss'
import Pic from "../../assets/images/stuttgart-card.png";
import {AppState} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {fetchAccountDetails} from "../../store/account-info.slice";
import {EditFilled} from "@ant-design/icons/lib";
import {useHistory} from "react-router-dom";
import HomepageOfferCard from "../../components/HomepageOfferCard";
import {
    fetchAvailableDepartureDates,
    fetchAvailableReturnDates,
    setDates,
    setFromAirport, setOneWay,
    setToAirport
} from "../../store/home";
import moment from "moment";
import DateUtils from "../../types/utils/DateUtils";
import SideOffers from "../../components/SideOffers";
import {ReactComponent as Arrow} from "../../assets/images/arrow-left.svg";
import {useTranslation} from "react-i18next";


const Account = () => {

    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const account = useSelector((state: AppState) => state.accountInfo.accountInfo);

    useEffect(() => {
        dispatch(fetchAccountDetails());
    }, []);

    return (
        <div className="account-container">

            <div className="row">
                <div className="col-12 col-lg-8">
                    <div className="d-flex justify-content-between mb-5 ml-4">
                        <div className="account-title">
                            {t("app.account.account.profile", "Profile")}
                        </div>
                        <button
                            className="account-edit-button"
                            onClick={() => history.push("/account/edit")}>
                            <EditFilled/> {t("app.account.account.edit", "Edit")}
                        </button>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12 col-lg-8">

                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <div className="account-image">
                                <img src={Pic}/>
                            </div>
                        </div>
                        <div className="col-12 col-lg-8">

                            <div className="account-name">{account?.fullName || "--"}</div>


                            <div className="account-section">{t("app.account.account.accountDetails", "ACCOUNT DETAILS")}</div>

                            <div className="account-label">{t("app.account.account.email", "Email Address")}</div>
                            <div className="account-value">{account?.email || "--"}</div>

                            <div className="account-label">{t("app.account.account.password", "Password")}</div>
                            <div className="account-value">***************</div>


                            <div className="account-section">{t("app.account.account.contactDetails", "CONTACT DETAILS")}</div>

                            <div className="account-label">{t("app.account.account.phoneNumber", "Phone Number")}</div>
                            <div className="account-value">{account?.phoneNumber || "--"}</div>


                            <div className="account-section">{t("app.account.account.personalDetails", "PERSONAL DETAILS")}</div>

                            <div className="row">
                                <div className="col-6 p-0">
                                    <div className="account-label">{t("app.account.account.dateOfBirth", "Date of birth")}</div>
                                    <div className="account-value">{account?.dateOfBirth || "--"}</div>
                                </div>
                                <div className="col-6">
                                    <div className="account-label">{t("app.account.account.gender", "Gender")}</div>
                                    <div className="account-value">{account?.gender || "--"}</div>
                                </div>
                            </div>

                            <div className="account-label">{t("app.account.account.city", "City")}</div>
                            <div className="account-value">{account?.city || "--"}</div>

                            <div className="account-label">{t("app.account.account.country", "Country")}</div>
                            <div className="account-value">{account?.country || "--"}</div>

                        </div>
                    </div>

                </div>

                <div className="col-md-12 col-lg-4 right-column">
                    <SideOffers/>
                </div>
            </div>

        </div>
    )
}

export default Account;
