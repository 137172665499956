import React from 'react'
import styles from "./FlightPreview.module.scss";
import moment from "moment";
import classNames from "classnames";
import {ReactComponent as AirplaneIcon} from "../../assets/images/ion_airplane.svg";
import {fetchDailyFlights} from "../../store/daliyFlights";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {useTranslation} from "react-i18next";


interface Props {
    flight: any;
    isReturnFlight?: boolean;
    className?: string;
}

const FlightPreview = (props: Props) => {

    const {t} = useTranslation();

    const {flight, isReturnFlight, className} = props;

    const formatPrice = () => {
        let price = flight.lowestPriceWithTax.split(".", 1);
        let symbol = flight.lowestPriceWithTax.split(".00 ", 2)[1];
        return price + " " + symbol;
    }

    const arrival = useSelector((state: AppState) => isReturnFlight ? state.dailyFlights.departure : state.dailyFlights.arrival);
    const departure = useSelector((state: AppState) => isReturnFlight ? state.dailyFlights.arrival : state.dailyFlights.departure);

    const classes = classNames(styles.flightPreviewContainer, className);

    return (
        // <div className={classes}>
        //     <div className={styles.flightPreviewTitle}>
        //         {/*{isReturnFlight ?*/}
        //         {/*    t("app.bookFlight.flights.flightPreview.return.title", "Return") :*/}
        //         {/*    t("app.bookFlight.flights.flightPreview.departure.title", "Departure")}:*/}
        //         <b className="ml-2">{moment(flight.dateOfDeparture, "DD/MM/YYYY").format("dddd, DD MMMM")}</b>
        //     </div>
        //     <div className={styles.flightPreview}>
        //         <div className={styles.top}>
        //             <div className={styles.airport}>{departure?.code}</div>
        //             <AirplaneIcon />
        //             <div className={styles.airport}>{arrival?.code}</div>
        //         </div>
        //         <div className={styles.middle}>
        //             <div className={styles.side}>
        //                 <div className={styles.airport}>{departure?.code}</div>
        //                 <div className={styles.time}>{moment(flight.timeOfDeparture, "hh:mm:ss").format("HH:mm")}</div>
        //             </div>
        //             <div className={styles.center}>
        //                 <div className={styles.time}>{flight.flightDuration}</div>
        //                 <div className={styles.info}>{flight.numberOfAvailableSeats} {t("app.bookFlight.flights.flightPreview.available", "available")}</div>
        //             </div>
        //             <div className={styles.side}>
        //                 <div className={styles.airport}>{arrival?.code}</div>
        //                 <div className={styles.time}> {moment(flight.timeOfArrival, "hh:mm:ss").format("HH:mm")}</div>
        //             </div>
        //         </div>
        //         <div className={styles.bottom}>
        //             <p></p>
        //             <p>{t("app.bookFlight.flights.flightRow.priceFrom", "from")} <b>{formatPrice()}</b></p>
        //             <p style={{display: 'flex', justifyContent: 'end'}}>{flight.gepack}{t("app.bookFlight.flights.flightRow.luggage", "kg of luggage included")}</p>
        //         </div>
        //     </div>
        // </div>

        <div className={classes}>
            <div className={styles.flightPreviewTitle}>
                {/*{isReturnFlight ?*/}
                {/*    t("app.bookFlight.flights.flightPreview.return.title", "Return") :*/}
                {/*    t("app.bookFlight.flights.flightPreview.departure.title", "Departure")}:*/}
                <b className="ml-2">{moment(flight.dateOfDeparture, "DD/MM/YYYY").format("dddd, DD MMMM")}</b>
            </div>
            <div className={styles.flightPreview}>
                <div className={styles.top}>
                    <div className={styles.airportName}>{departure?.code}</div>
                    <AirplaneIcon />
                    <div className={styles.airportName}>{arrival?.code}</div>
                </div>
    <div className={styles.infoContainer}>
        <div className={styles.flightPreviewDetails}>
            <div className={styles.flightInfo}>
                <div className={styles.date}>
                    {flight.departureDate ? moment(flight.departureDate, "DD/MM/YYYY").format("dddd, DD MMMM") : ''}
                </div>
                <div className={styles.time}>
                    {moment(flight.timeOfDeparture, "hh:mm:ss").format("HH:mm")}
                </div>
                <div className={styles.airport}>{departure.name}</div>
            </div>
            <div className={styles.horizontal}>
                <AirplaneIcon/>
                <p className={styles.flightTime}>Flight time: {flight.flightDuration}</p>
            </div>
            <div className={styles.flightInfo}>
                <div className={styles.date}>
                    {flight.departureDate ? moment(flight.departureDate, "DD/MM/YYYY").format("dddd, DD MMMM") : ''}
                </div>
                <div className={styles.time}>
                    {moment(flight.timeOfArrival, "hh:mm:ss").format("HH:mm")}
                </div>
                <div className={styles.airport}>{arrival.name}</div>
            </div>
        </div>
    </div>
    </div></div>
    )
}

export default FlightPreview;
