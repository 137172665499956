import React, {useEffect} from "react";
import { Route, Redirect } from "react-router-dom";
import {useDispatch} from "react-redux";
import {setLoginDrawer} from "../../store/auth";

interface Props {
    path: string;
    component: any;
    authenticated: boolean;
    exact?: boolean;
}

const AuthRoute = ({ path, component, authenticated, exact }: Props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if(!authenticated) {
            dispatch(setLoginDrawer(true));
        }
    }, []);

    return authenticated ?
            <Route path={path} component={component} exact={exact}/>
            : <Redirect to="/"/>
}

export default AuthRoute;
