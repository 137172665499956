import {combineReducers} from '@reduxjs/toolkit';

import weeklyFlights from "./weeklyFlights";
import dailyFlights from "./daliyFlights";
import selectedFlights from "./selectedFlights";
import passengers from "./passengers";
import countries from "./countries";
import offers from "./offers";

import auth from "./auth";
import home from "./home";
import reset from "./resetPassword"

import accountInfo from './account-info.slice';
import accountUpdate from './account-update-info';
import accountForm from './account-form';

import reservations from './reservations';

import phoneNumber from './get-phone-numbers.slice';

export default combineReducers( {
    auth,
    home,
    weeklyFlights,
    dailyFlights,
    selectedFlights,
    passengers,
    offers,
    countries,
    reservations,
    accountInfo,
    accountUpdate,
    accountForm,
    reset,
    phoneNumber
});

