import axios from "./axios.interceptors";

class ContactService {
  public contact = async (body: any): Promise<any> => {
    try {
      const response = await axios.post(`/api/v1/contact`, body);
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}

export default new ContactService();
