import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {addToLSObject} from "../utils";

export const setPassengers = (body: any) => async (dispatch: Dispatch) => {
    dispatch(passengers(body));
}

export const clearPassengers = () => (dispatch: Dispatch) => {
    dispatch(passengers([]));
}

interface SelectedFlightSlice {
    passengers: any;
}

const initialState = (): SelectedFlightSlice => {
    let localStorageData;

    try {
        localStorageData = JSON.parse((window as any).localStorage.getItem("pr_jet_passengers_data"));
    } catch (error) {
        localStorageData = null;
        console.warn('There was a problem with LS', error)
    }

    return {
        passengers: [],
        ...localStorageData
    }
}

const stateSlice = createSlice({
    name: 'bookflight/passengers',
    initialState: initialState(),
    reducers: {
        passengers: (state: any, action) => {
            addToLSObject("pr_jet_passengers_data", "passengers", action.payload);
            state.passengers = action.payload
        },
    }
});

export const {passengers} = stateSlice.actions;

export default stateSlice.reducer;
