import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router";
import Passengers from "./Passengers/Passengers";
import Flights from "./Flights/Flights";
import Payment from "./Payment/Payment";
import Overview from "./Overview/Overview";
import { Steps } from "antd";
import "./BookFlight.scss";
import { getQueryParameterByName } from "../../utils";
import { useHistory } from "react-router-dom";
import AuthRoute from "../../components/Routes/AuthRoute";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import Stepper from "../../components/Stepper/Stepper";
import PaymentMethods from "./PaymentMethods/PaymentMethods";
import MakePayment from "./MakePayment/MakePayment";
import {useTranslation} from "react-i18next";

const { Step } = Steps;

const BookFlight = (props: any) => {
  const {t} = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const accessToken = useSelector((state: AppState) => state.auth.accessToken);

  const departureAirport = getQueryParameterByName("from");
  const arrivalAirport = getQueryParameterByName("to");
  const departureDate = getQueryParameterByName("departure");
  const returnDate = getQueryParameterByName("return");
  const adults = getQueryParameterByName("adults");
  const children = getQueryParameterByName("children");
  const infants = getQueryParameterByName("infants");
  const source = getQueryParameterByName("source");
  const language = getQueryParameterByName("language");

  const currentStep = () => {
    if (location.pathname == "/booking/flights") {
      return 0;
    } else if (location.pathname == "/booking/passengers") {
      return 1;
    } else {
      return 2;
    }
  };

  let timeout: any = null;

  useEffect(() => {
    // timeout = setTimeout(() => {
    //     alert("Your session timed out. You have to restart the booking process!");
    //     restart();
    // }, 10000);
  }, []);

  const refreshTimeout = () => {
    // clearTimeout(timeout);
    // timeout = null;
    // timeout = setTimeout(() => {
    //     alert("Your session timed out. You have to restart the booking process!");
    //     restart();
    // }, 10000);
  };

  const restart = () => {
    history.push({
      pathname: "/booking/flights",
      search: `?from=${departureAirport}&to=${arrivalAirport}&departure=${departureDate}${
        returnDate ? "&return=" + returnDate : ""
      }&adults=${adults}${children ? "&children=" + children : ""}${
        infants ? "&infants=" + infants : ""
      }${source ? "&source=" + source : ""}${language ? "&language=" + language : ""}`,
    });
  };

  const eraseTimeout = () => {
    // clearTimeout(timeout);
  };

  return (
    <div className="book-flight-container">
      <div className="booking-steps">
        <Steps direction="horizontal" current={currentStep()}>
          <Step title={t("app.bookFlight.flights.steps.title", "Flights")}/>
          <Step title={t("app.bookFlight.flights.steps.title1", "Passengers")} />
          <Step title={t("app.bookFlight.flights.steps.title2", "Payment")} />
        </Steps>
      </div>
      <div className="book-flight-content">
        <Switch>
          <Route path="/booking/flights" render={() => <Flights />} />
          <Route path="/booking/passengers" render={() => <Passengers />} />
          <Route path="/booking/overview" component={Overview} />
          <Route
            path="/booking/:reservationId/make-payment"
            component={MakePayment}
          />
          <Route path="/booking/payment-methods" component={PaymentMethods} />
          <Route path="/booking/payment" component={Payment} />
        </Switch>
      </div>
    </div>
  );
};

export default BookFlight;
