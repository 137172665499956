import React from 'react';
import DocumentMeta from 'react-document-meta';

class DocumentMetaTest extends React.Component {
    render() {
        const meta = {
            title: 'Prishtina Jet',
            description: 'PrishtinaJet ofron luturime direkte nga Basel, Düsseldorf, Stuttgart, München drejt Prishtinës me çmimet më të mira dhe fluturimet më të rehatshme në treg',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'PrishtinaJet, Prishtina Jet, Flugtickets nach Prishtina, oferta per fluturime, fluturime drejt prishtine, fluturime kosove, Flugtickets nach Kosovo, flugtickets Prishtina'
                }
            }
        };

        return (
            <DocumentMeta {...meta}>
            </DocumentMeta>
        );
    }
}

export default DocumentMetaTest;