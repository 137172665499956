import axios from './axios.interceptors';
// import {resetPassword} from "../store/resetPassword";
import {ResetPasswordTypes} from "../types/UserTypes"


class ResetPasswordService{
    resetPassword = async (body: ResetPasswordTypes): Promise<any> => {
        const accessToken = localStorage.getItem("pr_jet_access_token");
        try {
            const response = await axios.post('/api/v1/account/password', body, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            return response;
        } catch (err) {
            throw err;
        }
    }

}

export default new ResetPasswordService();