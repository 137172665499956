import styles from '../ErrorResult.module.scss';
import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Modal} from "antd";
import {useHistory} from "react-router-dom";
import {resetHomeForm} from "../../../store/home";
import {clearPassengers} from "../../../store/passengers";
import {clearFlights} from "../../../store/selectedFlights";
import {useDispatch} from "react-redux";
import {ReactComponent as ErrorIcon} from "../../../assets/images/error.svg";

const ErrorDialog = (props: any) => {
  const {isVisible} = props;
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const localStorageData = JSON.parse((window as any).localStorage.getItem("pr_jet_home_data")) || null;

  const handleRetryClick = () => {
    if (!localStorageData || !localStorageData.dates[0]) {
      history.push({pathname: '/'})
    } else {
      const departureAirport = localStorageData?.fromAirport;
      const arrivalAirport = localStorageData?.toAirport;
      const departureDate = localStorageData?.dates[0];
      const returnDate = localStorageData?.dates[1];
      const adults = localStorageData?.adults;
      const children = localStorageData?.children;
      const infants = localStorageData?.infants;
      history.push({
        pathname: '/booking/flights',
        search: `?from=${departureAirport}&to=${arrivalAirport}&departure=${departureDate}${returnDate ? "&return=" + returnDate : ""}&adults=${adults}${children ? "&children=" + children : ""}${infants ? "&infants=" + infants : ""}`
      });
    }
  }

  const handleHomeClick = () => {
    dispatch(resetHomeForm());
    dispatch(clearPassengers());
    dispatch(clearFlights());
    history.push("/");
  }

  return (
      <Modal title={t("app.errorResult.errorDialogTitle", "Error")}
             visible={isVisible}
             width='800px'
             footer={null}
             onCancel={handleHomeClick}
      >
        <div className={styles.errorResult} style={{boxShadow: 'none'}}>
          <ErrorIcon/>
          <p className="mt-5">{t("app.bookFlight.result.error.title", "An error occurred and your flight couldn’t be booked.")}</p>
          <p>{t("app.bookFlight.result.error.subtitle", "Please try again.")}</p>
          <div className={styles.errorResultButtons}>
            <Button
                htmlType="button"
                onClick={handleRetryClick}
                className="button-primary mb-3">
              {t("app.bookFlight.result.error.buttons.retry", "Retry")}
            </Button>
            <Button
                htmlType="button"
                onClick={handleHomeClick}
                className="button-outline">
              {t("app.bookFlight.result.success.buttons.home", "Home")}
            </Button>
          </div>
        </div>
      </Modal>)
}

export default ErrorDialog;