import React, {useEffect, useState, useRef} from "react";
import BookingInformation from "./components/BookingInformation";
import ContactPerson from "./components/ContactPerson";
import {Divider, Checkbox, Button} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import {useHistory} from "react-router-dom";
import {clearPassengers, setPassengers} from "../../../store/passengers";
import {setLoginDrawer} from "../../../store/auth";
import {useLocation} from "react-router";
import {getQueryParameterByName} from "../../../utils";
import {fetchAirports} from "../../../store/countries";
import {fetchOffers} from "../../../store/offers";
import {AuthService, PaymentService} from "../../../actions";
import {resetHomeForm} from "../../../store/home";
import {
  clearFlights,
  fetchPricePreview,
} from "../../../store/selectedFlights";
import Spinner from "../../../components/Spinner/Spinner";
import classNames from "classnames";
import {ReactComponent as Arrow} from "../../../assets/images/right-arrow.svg";
import {useTranslation} from "react-i18next";
import TermsConditions from "./components/Terms&Conditions";
import PublicContactPerson from "./components/PublicContactPerson";
import BookFlightFooter from "../BookFlightFooter";
import PrivacyPolicy from "./components/Privacy&Policy";

const Overview = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const {t} = useTranslation();
  const contactPersonRef = useRef() as any;

  const departureAirport = getQueryParameterByName("from");
  const arrivalAirport = getQueryParameterByName("to");
  const departureDate = getQueryParameterByName("departure");
  const returnDate = getQueryParameterByName("return");
  const adults = getQueryParameterByName("adults");
  const children = getQueryParameterByName("children");
  const infants = getQueryParameterByName("infants");
  const source = getQueryParameterByName("source");
  const language = getQueryParameterByName("language");

  const price = useSelector(
      (state: AppState) => state.selectedFlights.pricePreview
  );
  const accountInfoState = useSelector(
      (state: AppState) => state.accountInfo.accountInfo
  );

  const departureFlight = useSelector(
      (state: AppState) => state.selectedFlights.departureFlight
  );
  const returnFlight = useSelector(
      (state: AppState) => state.selectedFlights.returnFlight
  );

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPrivacyModalVisible, setIsPrivacyModalVisible] = useState(false);


  const nextStep = () => {
    history.push({
      pathname: "/booking/payment-methods",
      search: `?from=${departureAirport}&to=${arrivalAirport}&departure=${departureDate}${
          returnDate ? "&return=" + returnDate : ""
      }&adults=${adults}${children ? "&children=" + children : ""}${
          infants ? "&infants=" + infants : ""
      }${source ? "&source=" + source : ""}${language ? "&language=" + language : ""}`,
    });
  };

  const onChange = (e: any) => {
    setAcceptedTerms(e.target.checked);
  };

  const onGoBackClick = () => {
    history.push({
      pathname: "/booking/passengers",
      search: `?from=${departureAirport}&to=${arrivalAirport}&departure=${departureDate}${
          returnDate ? "&return=" + returnDate : ""
      }&adults=${adults}${children ? "&children=" + children : ""}${
          infants ? "&infants=" + infants : ""
      }${source ? "&source=" + source : ""}${language ? "&language=" + language : ""}`,
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
    setIsPrivacyModalVisible(false);
  };

  const showPrivacyModal = () => {
    setIsPrivacyModalVisible(true);
  };

  return (
      <div className="overview-container">
        <div className={classNames("overview", {["opacity-60"]: loading})}>
          <div className="flights-page-back">
            <div className="flights-back-arrow" onClick={onGoBackClick}>
              <Arrow/>
              {t("app.bookFlight.header.goBack", "Go back")}
            </div>
          </div>
          {loading && <Spinner center/>}
          <h2 style={{marginBottom: "3.3rem"}}>
            {t("app.bookFlight.overview.title", "Overview")}
          </h2>
          <BookingInformation/>
          <Divider/>
          <ContactPerson ref={contactPersonRef} onFinishForm={nextStep}/>
          {/*<PublicContactPerson/>*/}
          <div className="terms-conditions">
            <Checkbox onChange={onChange} checked={acceptedTerms}/>
            <div>
              <p style={{marginBottom: '0'}}>{t("app.bookFlight.overview", "By clicking Continue you agree to the Website")}</p>
              <div style={{display: "flex"}}>
                {<TermsConditions isVisible={isModalVisible}
                                  showModal={showModal}
                                  handleClose={handleClose}
                />} {<p>&</p>} {<PrivacyPolicy isVisible={isPrivacyModalVisible}
                                      showModal={showPrivacyModal}
                                      handleClose={handleClose}/>}
              </div>
            </div>
          </div>
        </div>

        <BookFlightFooter
            continue={() => contactPersonRef.current.submitForm()}
            disableContinue={!acceptedTerms}
        />
      </div>
  );
};

export default Overview;
