import React, {useEffect} from 'react';
import "./Print.module.css";
import JsBarcode from "jsbarcode";
import styles from './Print.module.css';
import {FlightLand} from "@material-ui/icons";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import DateUtils from "../../types/utils/DateUtils";
import PrJetLogo from './../../assets/images/PrishtinaJet-LOGO.svg';
import PrintInvoiceSharedInfo from "./PrintInvoiceSharedInfo";
import {fetchPhoneNumbers} from "../../store/get-phone-numbers.slice";
import {useDispatch} from "react-redux";

type iProps = {
  reservation?: any
  showDetails?: boolean
}

const Invoice = (({
                    reservation,
                    showDetails
                  }: iProps) => {

  const dispatch = useDispatch();

  useEffect(() => {
    JsBarcode("#barcode", reservation?.confirmationCode, {
      font: 'Roboto',
      fontSize: 14,
      height: 45,
      width: 3,
      displayValue: false
    });
  })

  useEffect(() => {
    dispatch(fetchPhoneNumbers());
  }, [])

  const date = DateUtils.getCurrentNewDate();
  const currency = reservation?.currency.isoCode;
  const travelers = reservation?.travelers;
  const traveler = travelers?.find((t: any) => t.id);

  return (
      <div className={styles.main}>
        <h1 className={styles.invoice}>Invoice</h1>
        <div className={styles.flex}>
          <div>
            <img src={PrJetLogo} alt="Logo" className={styles.logo}/>
            <p><b>Tour operation:</b></p>
            <p>Prishtina Jet L.L.C</p>
          </div>
          <div className={styles.mainTitle}>
            <h4>Ticket</h4>
            <h4>Bileta</h4>
          </div>
          <div>
            <svg id="barcode"></svg>
            <div className={styles.barcode}>
              <p>Kunden-Nr: <b>#{reservation?.confirmationCode}</b></p>
              <p>Nr i rezervimit: <b>#{reservation?.confirmationCode}</b></p>
            </div>
            <div className={styles.center}>
              <p>Abrechnungszeit / Faturuar: </p>
              <p><b>{date}</b></p>
              <br/>
            </div>
          </div>
        </div>
        <div className={styles.marginTop50}>
          <table className={styles.table}>
            <thead>
            <tr className={styles.title}>
              <td>Fluturimi</td>
              <td>Depart / Nisja</td>
              <td>Arrival / Mbrritja</td>
              <td>Gepack / Bagazhi</td>
              <td>Flight / Fluturimi</td>
            </tr>
            </thead>
            <tbody className="table-body-style">
            <tr>
              <td>
                <FlightTakeoffIcon fontSize='small' style={{marginRight: '0.25rem'}}/>
                {traveler?.departureFlight?.departureAirport}
                (<b>{traveler?.departureFlight?.departureAirportCode}</b>)
                =&gt; {traveler?.departureFlight?.arrivalAirport}
                (<b>{traveler?.departureFlight?.arrivalAirportCode}</b>)
              </td>
              <td>
                {traveler?.departureFlight?.dateOfDeparture} {traveler?.departureFlight?.timeOfDeparture}
              </td>
              <td>
                {traveler?.departureFlight?.dateOfDeparture} {traveler?.departureFlight?.timeOfArrival}
              </td>
              <td>
                {reservation?.departureGepack} kg
                / {reservation?.departureGepackInf} kg inf
              </td>
              <td>
                <b>{traveler?.departureFlight?.flightNumber}</b>
              </td>
            </tr>
            {traveler?.returningFlight && (
                <tr>
                  <td>
                    <FlightLand fontSize='small' style={{marginRight: '0.25rem'}}/>
                    {traveler.returningFlight?.departureAirport}
                    (<b>{traveler?.returningFlight?.departureAirport}</b>)
                    =&gt; {traveler.returningFlight?.arrivalAirport}
                    (<b>{traveler?.returningFlight?.arrivalAirport}</b>)
                  </td>
                  <td>
                    {traveler.returningFlight?.dateOfDeparture} {traveler?.returningFlight?.timeOfDeparture}
                  </td>
                  <td>
                    {traveler.returningFlight?.dateOfDeparture} {traveler?.returningFlight?.timeOfArrival}
                  </td>
                  <td>
                    {reservation?.returnGepack} kg
                    / {reservation?.returnGepackInf} kg inf
                  </td>
                  <td>
                    <b>{traveler?.returningFlight?.flightNumber}</b>
                  </td>
                </tr>
            )}
            </tbody>
          </table>
        </div>
        <div style={{marginTop: '50px'}}>
          <table className={styles.table}>
            <thead>
            <tr className={styles.title}>
              <td>Passagier / Pasagjeri</td>
              <td>Status</td>
              {showDetails && (
                  <>
                    <td>Price / Qmimi</td>
                    <td>Tax / Taxa</td>
                    <td>Total / Shuma</td>
                  </>
              )}
            </tr>
            </thead>
            <tbody className="table-body-style">
            {travelers?.map((traveler: any, index: any) => (
                <tr>
                  <td>
                    {index + 1})
                    {traveler.ageCategory === 'ADULT' ?
                        traveler.gender === 'MALE' ? 'MR' : 'MRS' : ''}
                    {traveler.ageCategory === 'CHILD' && 'CHD'}
                    {traveler.ageCategory === 'INF' && 'INF'}
                    . <b>{traveler?.name} {traveler?.surname}</b>
                    {!traveler?.returningFlight ? "- (OW)" : "- (RT)"}
                  </td>
                  {traveler?.status === "OK" ? (
                      <td>OK</td>
                  ) : (
                      <td>...</td>
                  )}
                  {showDetails && (
                      <>
                        <td>
                          {traveler?.price.split('.')[0]} {currency}
                        </td>
                        <td>
                          {traveler?.tax.split('.')[0]} {currency}
                        </td>
                        <td>
                          {traveler?.totalPrice.split('.')[0]} {currency}
                        </td>
                      </>
                  )}
                </tr>
            ))}
            </tbody>
          </table>
          {showDetails && (
              <div className={styles.totalContainer}>
                <div className={`${styles.flex} ${styles.total}`}>
                  <p>Gesamt / Totali :</p>
                  <p>{reservation?.amount.split('.')[0]} {currency}</p>
                </div>
                <div className={`${styles.flex} ${styles.total}`}>
                  <p>Steuern / Taksat :</p>
                  <p>{reservation?.tax.split('.')[0]} {currency}</p>
                </div>
                <div className={`${styles.flex} ${styles.total}`}>
                  <p>Gesamtsumme / Grand total :</p>
                  <p className={styles.fontBold}>{reservation?.totalAmount.split('.')[0]} {currency}</p>
                </div>
              </div>
          )}
        </div>
        <div style={{marginTop: '100px'}}>
          <p>Freigepäckgrenze: {traveler?.departureFlight?.gepack} kg für ein
            Gepäckstück pro Person.</p>
          <p>Bagazhi i lejuar: Një valixhe për person,
            maksimumi {traveler?.departureFlight?.gepack} kg.</p>
        </div>
        <PrintInvoiceSharedInfo/>
      </div>
  )
});

export default Invoice;