import React from "react";
import styles from './Details.module.scss';
import AirplaneIcon from "../../assets/images/airplane.png";
import QrCode from "../../assets/images/qr-code.png";
import moment from "moment";

interface Props {
    traveler: any;
}

const Details = ({traveler}: Props) => {
    return (
        <div className={styles.detailsContainer}>
            <div className={styles.detailsContainerCard}>
                <div className={styles.header}>
                    {traveler.departureFlight.departureAirportCode}
                    <img src={AirplaneIcon}/>
                    {traveler.departureFlight.arrivalAirportCode}
                </div>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <div className={styles.columnHeader}>Name</div>
                        <div className={styles.columnValue}>{traveler.name}</div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.columnHeader}>Surname</div>
                        <div className={styles.columnValue}>{traveler.surname}</div>
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.col}>
                        <div className={styles.columnHeader}>Departure</div>
                        <div className={styles.columnValue}>{moment(traveler.departureFlight.timeOfDeparture, "hh:mm:ss").format("hh:mm")}</div>
                        <div className={styles.columnHeader}>{moment(traveler.departureFlight.dateOfDeparture, "DD/MM/YYYY").format("dddd, MMMM D")}</div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.columnHeader}>Arrival</div>
                        <div className={styles.columnValue}>{moment(traveler.departureFlight.timeOfArrival, "hh:mm:ss").format("hh:mm")}</div>
                        <div className={styles.columnHeader}>{moment(traveler.departureFlight.dateOfDeparture, "DD/MM/YYYY").format("dddd, MMMM D")}</div>
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.col}>
                        <div className={styles.columnHeader}>Duration</div>
                        <div className={styles.columnValue}>{traveler.departureFlight.duration}</div>
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.col}>
                        <div className={styles.columnHeader}>Flight No.</div>
                        <div className={styles.columnValue}>{traveler.departureFlight.flightNumber}</div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.columnHeader}>Gate</div>
                        <div className={styles.columnValue}>{traveler.departureFlight.gate}</div>
                    </div>
                </div>

                <div className={styles.qrCode}>
                    <img src={QrCode} alt="qr-code"/>
                    <div>
                        <b>Flight QR Code</b>
                        <div>Tap to enlarge</div>
                    </div>
                </div>
            </div>

            {traveler.returningFlight && <div className={styles.detailsContainerCard}>
                <div className={styles.header}>
                    {traveler.returningFlight.departureAirportCode}
                    <img src={AirplaneIcon}/>
                    {traveler.returningFlight.arrivalAirportCode}
                </div>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <div className={styles.columnHeader}>Name</div>
                        <div className={styles.columnValue}>{traveler.name}</div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.columnHeader}>Surname</div>
                        <div className={styles.columnValue}>{traveler.surname}</div>
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.col}>
                        <div className={styles.columnHeader}>Departure</div>
                        <div className={styles.columnValue}>{moment(traveler.returningFlight.timeOfDeparture, "hh:mm:ss").format("hh:mm")}</div>
                        <div className={styles.columnHeader}>{moment(traveler.returningFlight.dateOfDeparture, "DD/MM/YYYY").format("dddd, MMMM D")}</div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.columnHeader}>Arrival</div>
                        <div className={styles.columnValue}>{moment(traveler.returningFlight.timeOfArrival, "hh:mm:ss").format("hh:mm")}</div>
                        <div className={styles.columnHeader}>{moment(traveler.returningFlight.dateOfDeparture, "DD/MM/YYYY").format("dddd, MMMM D")}</div>
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.col}>
                        <div className={styles.columnHeader}>Duration</div>
                        <div className={styles.columnValue}>{traveler.returningFlight.duration}</div>
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.col}>
                        <div className={styles.columnHeader}>Flight No.</div>
                        <div className={styles.columnValue}>{traveler.returningFlight.flightNumber}</div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.columnHeader}>Gate</div>
                        <div className={styles.columnValue}>{traveler.returningFlight.gate}</div>
                    </div>
                </div>

                <div className={styles.qrCode}>
                    <img src={QrCode} alt="qr-code"/>
                    <div>
                        <b>Flight QR Code</b>
                        <div>Tap to enlarge</div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default Details;
