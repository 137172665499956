import React from "react";
import {Button, Form, Input} from "antd";
import {forgotPassword, forgotPasswordModal} from "../../store/auth";
import {useDispatch} from "react-redux";

interface Props {
  onClose: any;
  onClickLogin: any;
}

const ForgotPasswordContent = ({onClose, onClickLogin}: Props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = (values: any) => {
    dispatch(forgotPassword(values, () => {
      onClickLogin();
      dispatch(forgotPasswordModal(false));
    }))

  };

  return (
      <div className="login-drawer-content">
        <div className="d-flex flex-column  mb-2">
          <h2 className="m-0">FORGOT PASSWORD</h2>
          <h4 className="m-0 mb-3">Type your email and get instructions how to reset your
            password</h4>
        </div>

        <Form form={form} name="control-hooks" onFinish={onFinish}>

          <Form.Item name="email" label="Email" rules={[{required: true, type: "email"}]}>
            <Input/>
          </Form.Item>

          <Form.Item>
            <div className="text-center mt-5">
              <Button
                  htmlType="submit"
                  className="button-primary">
                Send
              </Button>

            </div>
          </Form.Item>
          <Form.Item className="justify-content-center" style={{marginTop: '13px'}}>
            <a>Remembering password ? </a>
            <a className="align-self-center" style={{margin: 'auto'}} href="" onClick={(e) => {
              e.preventDefault();
              onClickLogin();
            }}>
              Go back to log in
            </a>
          </Form.Item>
        </Form>
      </div>
  )
}

export default ForgotPasswordContent;