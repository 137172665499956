import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {PhoneNumberTypes} from "../types/service-types/PhoneNumberTypes";
import PhoneNumberService from "../actions/phone-number.service";

export const fetchPhoneNumbers = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await PhoneNumberService.fetchNumbers();
    dispatch(setPhoneNumbers(response));
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
  }
}

interface PhoneNumbersSlice {
  phoneNumbers: PhoneNumberTypes[] | null;
  loading: boolean;
}

const initialState: PhoneNumbersSlice = {
  phoneNumbers: null,
  loading: false
}

const stateSlice = createSlice({
  name: 'schedules/phone-numbers',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setPhoneNumbers: (state, action) => {
      state.phoneNumbers = action.payload
    }
  }
});

export const {setLoading, setPhoneNumbers} = stateSlice.actions;

export default stateSlice.reducer;
