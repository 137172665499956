import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import {PaymentService} from "../../../actions";
import {useHistory} from "react-router-dom";
import {useLocation, useParams} from "react-router";
import React, {useEffect, useState} from "react";
import {fetchPricePreview,} from "../../../store/selectedFlights";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import {ReactComponent as Arrow} from "../../../assets/images/right-arrow.svg";
import Spinner from "../../../components/Spinner/Spinner";
import {getQueryParameterByName} from "../../../utils";
import "./PaymentMethods.scss";
import PaymentCard from "./PaymentCard";
import payseraLogo from "../../../assets/images/paysera.png";
import visaLogo from "../../../assets/images/Visa_Brandmark_Blue_RGB_2021.png";
import masterLogo from "../../../assets/images/mc_symbol_opt_73_2x.png";
import manualPaymentLogo from "../../../assets/images/credit-card 1.svg";
import BookFlightFooter from "../BookFlightFooter";
import {setDrawerContent, setLoginDrawer} from "../../../store/auth";
import AuthenticationDrawer from "../../../components/AuthenticationDrawer/AuthenticationDrawer";
import AuthService from "../../../actions/auth.service";
import ErrorDialog from "../../../components/ErrorResult/ErrorDialog/ErrorDialog";


const PaymentMethods = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const location = useLocation() as any;
  const routeParams = useParams();
  const state = location.state || null;
  const env = process.env.REACT_APP_ENVIRONMENT || 'DEV';


  const authService = AuthService;
  const isDrawerOpen = useSelector((state: AppState) => state.auth.isDrawerOpen);

  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const departureAirport = getQueryParameterByName("from");
  const arrivalAirport = getQueryParameterByName("to");
  const departureDate = getQueryParameterByName("departure");
  const returnDate = getQueryParameterByName("return");
  const adults = getQueryParameterByName("adults");
  const children = getQueryParameterByName("children");
  const infants = getQueryParameterByName("infants");
  const source = getQueryParameterByName("source");
  const language = getQueryParameterByName("language");

  const accountInfoState = useSelector(
      (state: AppState) => state.accountInfo.accountInfo
  );
  const departureFlight = useSelector(
      (state: AppState) => state.selectedFlights.departureFlight
  );
  const returnFlight = useSelector(
      (state: AppState) => state.selectedFlights.returnFlight
  );
  const passengers = useSelector(
      (state: AppState) => state.passengers.passengers
  );

  useEffect(() => {
    if (departureFlight && !state?.debt) {
      const priceParams = {
        numberOfAdults: adults || 0,
        numberOfChildren: children || 0,
        numberOfInfants: infants || 0,
        departureFlightId: departureFlight.id,
        returnFlightId: returnFlight?.id,
      };
      dispatch(fetchPricePreview(priceParams));
    }
  }, [departureFlight, returnFlight]);

  const onGoBackClick = () => {
    if (state?.from) {
      history.goBack();
      return;
    }
    history.push({
      pathname: "/booking/overview",
      search: `?from=${departureAirport}&to=${arrivalAirport}&departure=${departureDate}${
          returnDate ? "&return=" + returnDate : ""
      }&adults=${adults}${children ? "&children=" + children : ""}${
          infants ? "&infants=" + infants : ""
      }${source ? "&source=" + source : ""}${language ? "&language=" + language : ""}`,
    });
  };

  const payFlight = (paymentType: string) => {
    if (paymentType === 'MANUAL') {
      if (!authService.isUserLoggedIn()) {
        dispatch(setLoginDrawer(true));
        return
      }
    }

    setLoading(true);

    const isSource = location.search.includes('source');
    const sourceValue = location.search.split('source=')[1]?.split('&')[0]?.split('?')[0];

    if (state?.from === 'make-payment') {
      PaymentService.requestPayment(state?.reservationId, paymentType).then((res) => {
        window.location.href = res.proceedOrderUrl;
        console.log(res);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        showErrorModal();
      });
      return;
    } else {
      PaymentService.checkout({
        travelers: passengers,
        contactNumber: accountInfoState.phoneNumber,
        fullName: accountInfoState.fullName,
        email: accountInfoState.email,
        source: source ? source.toLocaleUpperCase() : "WEB",
        paymentType,
      })
      .then((res) => {
        window.location.href = res.proceedOrderUrl;
        console.log(res);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        // alert("Error");
        showErrorModal();
      });
    }
  };

  const showErrorModal = () => {
    setIsError(true);
  }

  const closeErrorModal = () => {
    setIsError(false);
  }

  return (
      <>
        <AuthenticationDrawer isDrawerOpen={isDrawerOpen} onClose={() => {
          dispatch(setLoginDrawer(false))
          dispatch(setDrawerContent('login'));
        }}/>

        <ErrorDialog isVisible={isError}
                     showModal={showErrorModal}
                     handleClose={closeErrorModal}
        />

        <div className="payment-methods_container">
          <div
              className={classNames("payment-methods", {["opacity-60"]: loading})}
          >
            <div className="flights-page-back">
              <div className="flights-back-arrow" onClick={onGoBackClick}>
                <Arrow/>
                {t("app.bookFlight.header.goBack", "Go back")}
              </div>
            </div>
            {loading && (
                <div className="spinner-center">
                  <h1>Completing your order...</h1>
                  <Spinner/>
                </div>)
            }
            <h2 className="m-b-3">
              {t("app.bookFlight.payment-methods.title", "Choose payment method")}
            </h2>

            <img src={masterLogo} style={{height: '50px', marginRight: '5px'}} alt="logo"/>
            <img src={visaLogo} style={{height: '35px'}} alt="logo"/>

            <div className="payment-methods_cards-wrapper m-b-3">
              {/*<PaymentCard
                  onClick={() => payFlight("PAYPAL")}
                  logo={paypalLogo}
                  description="Pay secure with PayPal"
              />*/}
              <PaymentCard
                  onClick={() => payFlight("PAYSERA")}
                  logo={payseraLogo}
                  description="Pay secure with PaySera"
                  logoVisa={visaLogo}
                  logoMaster={masterLogo}
              />
              {(env === 'DEV' || env === 'PROD') && (
                  <PaymentCard
                      onClick={() => payFlight("MANUAL")}
                      logo={manualPaymentLogo}
                      description={'Manual Payment'}
                  />
              )}
            </div>
          </div>
          <BookFlightFooter hideContinue={true} totalPrice={state?.debt}/>
        </div>
      </>
  );
};

export default PaymentMethods;
