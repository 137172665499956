import React, {useEffect, useState} from 'react';
import "../overview.scss"
import {useSelector} from "react-redux";
import {AppState} from "../../../../store";
import {useTranslation} from "react-i18next";
import FlightsService from "../../../../actions/flights.service";
import Passenger from "../../../../components/Passenger";

const BookingInformation = ({dateOfDeparture, dateOfArrival, departureCity, arrivalCity, code, ageCategory, firstName, lastName, gender, birthday}: any) => {

    const passengersPsg = useSelector((state: AppState) => state.passengers.passengers);
    const selectedDepartureFlight = useSelector((state:AppState) => state.selectedFlights.departureFlight);
    const selectedReturnFlight = useSelector((state: AppState) => state.selectedFlights.returnFlight);
    const {t} = useTranslation();

    const [flight, setFlight] = useState<any>(null);
    // const [returnFlight, setReturnFlight] = useState<any>({});

    useEffect(() => {
        FlightsService.getFlightById(selectedDepartureFlight.id).then(res => {
            setFlight(res);
        });
    }, [])

    return (
        <div>
            <div className="information">

                {flight && ((Object.keys(selectedReturnFlight).length !== 0) ?
                    <div className="flight-info">
                        <p>{selectedDepartureFlight.dateOfDeparture}  -  {selectedReturnFlight.dateOfDeparture}</p>
                        <h2>{flight.departureAirport.name} - {flight.arrivalAirport.name} - {flight.departureAirport.name}</h2>
                    </div>
                    : <div className="flight-info">
                        <p>{selectedDepartureFlight.dateOfDeparture}  - {t("app.home.form.return.oneway", "ONE WAY")}</p>
                        <h2>{flight.departureAirport.name} - {flight.arrivalAirport.name}</h2>
                    </div> )}

                <div className="confirmation-code-info">
                    {t("app.bookFlight.overview.bookingInformation.confirmationCode", "Confirmation code")}
                    <span>#{flight?.number}</span>
                </div>
                {passengersPsg.map((passenger: any) =>
                    <Passenger passenger={passenger} />
                )}
            </div>
        </div>

    )
}

export default BookingInformation;
