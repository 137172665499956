import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

const getInitialState = () => ({
    fullName: '',
    email: '',
    phoneNumber: '',
    address: '',
    gender: '',
    dateOfBirth: '',
    city: '',
    country: ''
});

export const setAccountInfoForm = (payload: any) => (dispatch: Dispatch) => {
    dispatch(setAccountForm(payload));
};

const stateSlice = createSlice({
    name: "account/account-form",
    initialState: getInitialState(),
    reducers: {
        setAccountForm: (state, action) => action.payload
    }
});

export const {setAccountForm} = stateSlice.actions;

export default stateSlice.reducer;