import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from './assets/locales/en.json';
import sq from './assets/locales/sq.json';
import de from './assets/locales/de.json';
import fr from './assets/locales/fr.json';
import nl from './assets/locales/nl.json';
import DateUtils from "./types/utils/DateUtils";

export const possibleLanguages = ["en", "sq", "de", "fr", "nl"];

const lng = DateUtils.parseLanguageLocaleString(localStorage.getItem("language"));
i18n.use(LanguageDetector).init({
  resources: {
    en: {translations: en},
    sq: {translations: sq},
    de: {translations: de},
    fr: {translations: fr},
    nl: {translations: nl},
  },
  lng: lng,
  debug: true,
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ","
  }
});

export default i18n;
