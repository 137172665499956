import React from 'react';
import styles from "./ErrorResult.module.scss";
import {ReactComponent as SuccessIcon} from "../../assets/images/error.svg";
import {Button} from "antd";
import {useTranslation} from "react-i18next";

const ErrorResult = (props: any) => {

    const {t} = useTranslation();

    return <div className={styles.errorResult}>
        <SuccessIcon />

        <p className="mt-5">{t("app.bookFlight.result.error.title", "An error occurred and your flight couldn’t be booked.")}</p>

        <p>{t("app.bookFlight.result.error.subtitle", "Please try again.")}</p>


        <div className={styles.errorResultButtons}>
            <Button
                htmlType="button"
                onClick={props.onRetryButtonClick}
                className="button-primary mb-3">
                {t("app.bookFlight.result.error.buttons.retry", "Retry")}
            </Button>
            <Button
                htmlType="button"
                onClick={props.onHomeButtonClick}
                className="button-outline">
                {t("app.bookFlight.result.success.buttons.home", "Home")}
            </Button>
        </div>
    </div>
}


export default ErrorResult;
