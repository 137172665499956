import axios from './axios.interceptors';
import qs from "querystring";

class AuthService {
  public login = async (payload: any): Promise<any> => {
    try {
      const response = await axios.post('/oauth/token', qs.stringify({
        grant_type: 'password',
        username: payload.email,
        password: payload.password
      }), {
        headers: {
          'Authorization': `Basic ${btoa('prjet_client_id:prjet_secret')}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  public rfshToken = async (payload: any): Promise<any> => {
    try {
      const refToken = localStorage.getItem("pr_jet_refresh_token")
      const response = await axios.post('/oauth/token', qs.stringify({
        grant_type: 'refresh_token',
        refresh_token: refToken
      }), {
        headers: {
          'Authorization': `Basic ${btoa('prjet_client_id:prjet_secret')}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  refreshTokenService(payload: any) {
    return axios.post("/oauth/token", payload, {
      headers: {
        Authorization: `Basic ${btoa("prjet_client_id:prjet_secret")}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }
  refresh_token() {
    const token = localStorage.getItem("pr_jet_refresh_token");
    const requestBody = qs.stringify({
      grant_type: "refresh_token",
      refresh_token: token,
    });

    return new Promise((resolve, reject) => {
      return this.refreshTokenService(requestBody)
      .then((res) => {
        localStorage.setItem("pr_jet_refresh_token", res.data.refresh_token);
        localStorage.setItem("pr_jet_access_token", res.data.access_token);
        resolve(res);
      })
      .catch((err) => {
        reject(err);
        console.log(err);
      });
    });
  }

  public forgotPassword = async (email: any): Promise<any> => {
    try {
      const response = await axios.post(`/api/account/forgot-password`, email,);
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  public resetPassword = async (body: any, key: string): Promise<any> => {
    try {
      const response = await axios.put(`/api/v1/account/reset-password`, body, {params: {key}});
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  public isUserLoggedIn = () => {
    const token = localStorage.getItem("pr_jet_refresh_token");
    return !!token;
  }
}

export default new AuthService();
