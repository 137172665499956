import React, { useEffect, forwardRef, useImperativeHandle } from "react";
import styles from "../../../../components/PassengerFormRow/PassengerFormRow.module.scss";
import classNames from "classnames";
import "./../overview.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  accountHandleChange,
  fetchAccountDetails,
} from "../../../../store/account-info.slice";
import { AppState } from "../../../../store";
import { Button, Input, Form } from "antd";
import { loginDrawer } from "../../../../store/auth";
import {useTranslation} from "react-i18next";

export interface ContactPersonProps {
  onFinishForm: () => void;
}
const ContactPerson = forwardRef((props: ContactPersonProps, ref) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {t} = useTranslation();

  useImperativeHandle(ref, () => ({
    submitForm() {
      return form.submit();
    },
  }));

  const accountInfoState = useSelector(
    (state: AppState) => state.accountInfo.accountInfo
  );

  const accessToken = useSelector((state: AppState) => state.auth.accessToken);

  useEffect(() => {
    dispatch(fetchAccountDetails());
  }, [accessToken]);

  const handleChange = (field: any) => {
    const name = Object.keys(field)[0];
    const value = field[name];
    dispatch(accountHandleChange({ name, value }));
  };
  const signIn = () => {
    dispatch(loginDrawer(true));
  };

  form.setFieldsValue({
    fullName: accountInfoState.fullName,
    email: accountInfoState.email,
    phoneNumber: accountInfoState.phoneNumber,
  });

  useEffect(() => {}, [accountInfoState]);

  return (
    <div className="contact-style">
      <div className="contact-style_header">
        <h3>{t("app.contactPerson", "Contact Person")}</h3>
        {!accessToken && (
          <Button htmlType="submit" className="button-primary" onClick={signIn}>
            Sign In
          </Button>
        )}
      </div>

      <Form
        form={form}
        onValuesChange={handleChange}
        onFinish={props.onFinishForm}
      >
        <div className={styles.row}>
          <div className={classNames(styles.col)}>
            <label htmlFor="nameInput" className={styles.passengerFormRowLabel}>
              {t("app.contactPerson.fullName", "FULL NAME")}
            </label>
            <div className="data-style" style={{ textTransform: "capitalize" }}>
              <Form.Item
                name="fullName"
                rules={[
                  { required: true, message: "Please input your full name!" },
                ]}
              >
                <Input
                  autoFocus={true}
                  style={{ border: "none", fontSize: "1.6rem" }}
                />
              </Form.Item>
            </div>
          </div>
          <div className={classNames(styles.col)}>
            <label htmlFor="nameInput" className={styles.passengerFormRowLabel}>
              {t("app.contactPerson.email", "EMAIL")}
            </label>
            <div className="data-style">
              <Form.Item
                name="email"
                rules={[
                  {
                    type:'email',
                    message: "Your email is not a valid email!",
                  },
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input style={{ border: "none", fontSize: "1.6rem" }} />
              </Form.Item>
            </div>
          </div>
          <div className={classNames(styles.col)}>
            <label htmlFor="nameInput" className={styles.passengerFormRowLabel}>
              {t("app.contactPerson.phoneNumber", "PHONE NUMBER")}
            </label>
            <div className="data-style">
              <Form.Item
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                  { pattern: /^\+(?:\d●?){6,14}\d$/gm,
                    message: 'Please start phone number with +'
                  }
                ]}
              >
                <Input
                  style={{ border: "none", fontSize: "1.6rem" }}
                  onKeyPress={(event) => {
                    if (!/[0-9 +]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
});

export default ContactPerson;
