import React, { useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { Button, Form, Input, Modal } from "antd";
import {auth, forgotPasswordModal, register, setDrawerContent} from "../../store/auth";
import { validateEmail } from "../../utils";
import classNames from "classnames";
import { EyeInvisibleOutlined, EyeTwoTone, InfoCircleOutlined } from "@ant-design/icons";
import {AppState} from "../../store";

interface Props {
  onClose: any;
  onClickLogin: any;
  onClickForgotPassword: any;
}

interface State {
  [key: string]: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  address: string;
  password: string;
  city: string;
  // zipCode: string;
  country: string;
}

const RegisterDrawerContent = ({ onClickLogin,onClickForgotPassword, onClose }: Props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const  modal = useSelector((state: AppState) => state.auth.forgotPasswordModal);

  const onFinish = (values: any) => {
    dispatch(register(values,()=>{
      form.resetFields();
      setTimeout(()=>{
        dispatch(setDrawerContent('login'));
      },1000)
    }));
  };


  const handleOk = () => {
    dispatch(forgotPasswordModal(false));
    onClickForgotPassword();
    form.resetFields();
  };

  const handleCancel = () => {
    dispatch(forgotPasswordModal(false))
  };



  return (
    <div className="register-drawer-content">
      <h1>SIGN UP</h1>

      <Form form={form} name="control-hooks" onFinish={onFinish}>
        <div className="row">
          <div className="col-12 col-lg-6">
            <Form.Item
              name="fullName"
              label="Full name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="col-12 col-lg-6">
            <Form.Item
              name="phoneNumber"
              label="Contact number"
              rules={[{ required: true, message: 'Please enter Contact number' },
                {pattern: /^\+(?:\d●?){6,14}\d$/gm, message: 'Please start Contact number with +'}
              ]}
            >
              <Input
                onKeyPress={(event) => {
                  if (!/[0-9 +]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-6">
            <Form.Item
              name="email"
              label="Email address"
              rules={[{ required: true, type: "email" }]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="col-12 col-lg-6">
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true }]}
            >
              <Input.Password
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-6">
            <Form.Item
              name="country"
              label="Country"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="col-12 col-lg-6">
            <Form.Item name="city" label="City" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Form.Item
              name="address"
              label="Address"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </div>
        </div>

        <Form.Item>
          <div className="text-center mt-5">
            <Button htmlType="submit" className="button-primary">
              Sign up
            </Button>
          </div>
        </Form.Item>
        <p className="mt-5 text-center">
          Already have an account?
          <a
            href="#"
            className="pl-2"
            onClick={(e) => {
              e.preventDefault();
              onClickLogin();
            }}
          >
            Sign In
          </a>
        </p>
      </Form>
      <Modal title="User already exists"
             visible={modal}
             onOk={handleOk}
             okText={'Yes'}
             cancelText={'No'}
             onCancel={handleCancel}>

        <div className="d-flex align-items-center">
          <h1 className="m-0 mb-4 text-primary" style={{fontSize:'4rem'}}>
            <InfoCircleOutlined />
          </h1>
        <h2 className="m-0 ml-3">Forgotten your password ?</h2>
        </div>
      </Modal>
    </div>
  );
};

export default RegisterDrawerContent;
