import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {OffersService} from "../actions";
import {OffersBody} from "../types/service-types/OffersTypes";

export const fetchOffers = () => async (dispatch: Dispatch) => {
    const response = await OffersService.getOffers();
    dispatch(setOffers(response));
}

interface bestOffersSlice {
    offers: OffersBody[];
}

const initialState: bestOffersSlice = {
    offers: [],
}

const stateSlice = createSlice({
    name: 'home/offers',
    initialState,
    reducers: {
        setOffers: (state, action) => {state.offers = action.payload}
    }
});

export const {setOffers} = stateSlice.actions;

export default stateSlice.reducer;
