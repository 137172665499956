import React from 'react';
import styles from "./Spinner.module.scss";
import classNames from "classnames";

const Spinner = (props: any) => {
    return <div className={classNames(styles.spinner, { [styles.center]: props.center })}>
        <div />
        <div />
        <div />
        <div />
    </div>
}


export default Spinner;
