import {EditFilled} from "@ant-design/icons";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Button, Form, Input, message} from "antd";
import {resetPassword} from "../../store/resetPassword";
import {useDispatch} from "react-redux";
import {useParams} from "react-router";
import {resetAccountPassword} from "../../store/auth";


const ResetAccountPassword = () => {
  const history = useHistory();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const params = useParams() as any;
  const key =params.key;

  const [changePasswordForm] = Form.useForm();


  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = () => {
    setConfirmLoading(true);

  };

  const handleCancel = () => {
    changePasswordForm.resetFields();
    setVisible(false);

  };

  function resetSuccess () {
    message.success('Password is changed successfully!');
    changePasswordForm.resetFields();
    setVisible(false)
  }


  return (
      <div className="account-container">
        <div className="row">
          <div className="col-12 ">
            <div className="d-flex justify-content-between mb-5 ml-4">
              <div className="account-title">
                {t("app.account.account.resetPassword", "Reset Password")}
              </div>
            </div>

            <div className="col-12 ">

              <Form className='form-style'
                    form={changePasswordForm}
                    initialValues={{oldPassword: '', newPassword: ''}}
                    onFinish={({newPassword, confirmNewPassword}) =>  {
                      dispatch(resetAccountPassword({newPassword, confirmNewPassword},key,resetSuccess));
                    }}>

                <Form.Item
                    name="newPassword"
                    label={t("app.account.editAccount.newPassword", "New Password")}
                    rules={[
                      {required: true, message: 'Please input your password!',},
                      {pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, message: 'Your new password must contain uppercase/lowercase letters, characters and numbers!'}
                    ]}>
                  <Input.Password/>
                </Form.Item>

                <Form.Item
                    name="confirmNewPassword"
                    label={t("app.account.editAccount.confirmNewPassword", "Confirm New Password")}
                    dependencies={['newPassword']}
                    rules={[{required: true, message: 'Please confirm your password!'},
                      ({getFieldValue}) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('newPassword') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                      }),
                    ]}>
                  <Input.Password/>
                </Form.Item>

                <div style={{display: 'flex', justifyContent:'end', marginTop: "2rem"}}>
                  <Button className="button-text-primary" onClick={handleCancel}> {t("app.account.editAccount.button.cancel", "Cancel")}</Button>
                  <Button htmlType="submit" className="button-primary" onClick={handleOk}> {t("app.account.editAccount.button.save", "Save")}</Button>
                </div>
              </Form>
            </div>
          </div>


        </div>

      </div>
  )
}

export default ResetAccountPassword;