import axios from './axios.interceptors';

class ReservationService {
    public getReservationById = async (id: string): Promise<any> => {
        try {
            const response = await axios.get(`/api/v1/reservations/${id}`);
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }
}

export default new ReservationService();
