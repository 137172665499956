import React from 'react'
import styles from "./HomepageOfferCard.module.scss";
import classNames from "classnames";
import AirplaneButton from "../../assets/images/airplane-button.svg";

interface Props {
  onClick?: any;
  className?: any;
  small?: boolean;
  offer?: any;
}

const HomepageOfferCard = ({offer, onClick, small, className}: Props) => {
  return (
      <div onClick={onClick} className={classNames(styles.homepageImageCard, className, {[styles.small]: small})}
          style={{backgroundImage: "linear-gradient(180deg, rgba(0, 0, 0, 0.3) , rgba(0, 0, 0, 0.3), rgba(20, 0, 0, 0.3)),url("+ offer.backdropImageUrl +")"}}>
          <div className={styles.offer}>
              <div className={styles.title}>
                  <div className={styles.departure}>{offer.departureLocation.location}</div>
                  <div className={styles.arrival}><span> From </span>{offer.arrivalLocation.location}</div>
                  {/*<span className={styles.status}>new destination</span>*/}
              </div>
          </div>
        <div className={styles.offerFooter}>
          <div className={styles.price}>
            <span>From </span><br/>
            <p>{offer.price}</p><br/>
          </div>
          <img src={AirplaneButton}/>
        </div>
      </div>
    )}

export default HomepageOfferCard;
