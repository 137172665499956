import axios from './axios.interceptors';
import {CustomerTypes, GetCustomerTypes} from "../types/service-types/CustomerTypes";

class CustomerServices {
    fetchAll = async (): Promise<GetCustomerTypes> => {
        try {
            const response = await axios.get('/api/customers');
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    createCustomer = async (body: CustomerTypes): Promise<any> => {
        try {
            return await axios.post('/api/v1/customers', body);
        } catch (err) {
            throw err;
        }
    }

    getAccountInfo = async (): Promise<any> => {
        const accessToken = localStorage.getItem("pr_jet_access_token");
        try {
            const response = await axios.get('/api/v1/account',{
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    updateAccountInfo = async (body: CustomerTypes) => {
        const accessToken = localStorage.getItem("pr_jet_access_token");
        try {
            return await axios.put(`/api/v1/account/`, body, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
        }
        catch(err) {
            throw err;
        }
    }
}

export default new CustomerServices();
