import React, {useState} from 'react';
import styles from './Header.module.scss';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Dropdown, Menu} from "antd";
import {DownOutlined} from "@ant-design/icons";
import classNames from "classnames";
import {useHistory} from "react-router";
import {ReactComponent as CloseIcon} from "../../assets/images/close.svg";
import {ReactComponent as BarsIcon} from "../../assets/images/bars.svg";
import DateUtils from "../../types/utils/DateUtils";
import Logo from "../../assets/images/beMyTicket.png"


interface Props {
  className?: string;
}

const Header = ({className}: Props) => {

  const history = useHistory();

  const [isExpanded, setIsExpanded] = useState(false);

  const {t, i18n} = useTranslation()

  const isMobile = window.innerWidth < 768;

  const onLanguageChange = (lang: any) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
  }

  const handleExpand = () => {
    setIsExpanded(true);
    if (isMobile) {
      document.body.classList.add("overflow-hidden");
    }
  }

  const handleCollapse = () => {
    setIsExpanded(false);
    document.body.classList.remove("overflow-hidden");
  }

  const languageMenu = (
      <Menu>
        <Menu.Item key="0">
          <span onClick={() => {
            onLanguageChange('en');
            handleCollapse();
          }}>English</span>
        </Menu.Item>
        <Menu.Item key="1">
          <span onClick={() => {
            onLanguageChange('de');
            handleCollapse();
          }}>German</span>
        </Menu.Item>
        <Menu.Item key="2">
          <span onClick={() => {
            onLanguageChange('fr');
            handleCollapse();
          }}>French</span>
        </Menu.Item>
        <Menu.Item key="3">
          <span onClick={() => {
            onLanguageChange('nl');
            handleCollapse();
          }}>Dutch</span>
        </Menu.Item>
        <Menu.Item key="5">
          <span onClick={() => {
            onLanguageChange('sq');
            handleCollapse();
          }}>Shqip</span>
        </Menu.Item>
      </Menu>
  );

  const classes = classNames(styles.header, className, {[styles.expanded]: isExpanded});
  const handleLinkClick = (event: any, link: string) => {
    event.preventDefault();
    history.push(link);
    handleCollapse()
  }
  const showLanguageName = (language: any) => {
    if (language === 'sq') {
      return (<span>SQ</span>)
    }
    if (language === 'en') {
      return (<span>EN</span>)
    }
    if (language === 'de') {
      return (<span>DE</span>)
    }
    if (language === 'fr') {
      return (<span>FR</span>)
    }
    if (language === 'nl') {
      return (<span>NL</span>)
    }
  }

  return (
      <div className={classes}>
        <Link onClick={(e) => handleLinkClick(e, "/")}
              to="#">
          <img width={210} src={Logo}/>
        </Link>
        <BarsIcon
            className={styles.barsIcon}
            onClick={() => handleExpand()}/>

        <nav className={styles.headerNavigation}>
          <CloseIcon
              className={styles.closeIcon}
              onClick={() => handleCollapse()}/>
          <ul>
            <li>
              <Link onClick={(e) => handleLinkClick(e, "/about")}
                    to="#">{t("app.header.navigation.about", "About us")}</Link></li>
            <li><Link onClick={(e) => handleLinkClick(e, "/tickets")}
                      to="#">{t("app.header.navigation.tickets", "Tickets")}</Link></li>
            <li><Link onClick={(e) => handleLinkClick(e, "/contact")}
                      to="#">{t("app.header.navigation.contact", "Contact")}</Link></li>
            <Dropdown
                overlayClassName={styles.headerDropdownMenuOverlay}
                overlay={languageMenu}
                trigger={['click']}
            >
              <a onClick={e => e.preventDefault()}>
                {showLanguageName(DateUtils.parseLanguageLocaleString(i18n.language))}
                <DownOutlined className="ml-2"/>
              </a>
            </Dropdown>
          </ul>
        </nav>
      </div>
  )
}

export default Header;
