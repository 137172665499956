class DateUtils {

    static convertDate(inputFormat: string | Date | undefined) {
        function pad(s: any) { return (s < 10) ? '0' + s : s; }
        if(inputFormat === undefined) return '';
        if(typeof inputFormat === 'string' && inputFormat.includes('/')) {
            return inputFormat;
        }
        let d = (typeof inputFormat === 'string') ? new Date(inputFormat) : inputFormat;

        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/');
    }

    static toISODate(date: string): string {
        if(!date) return '';
        if(!date.includes('/')) return date;

        // 18/03/2021
        const dateSplit = date.split('/');
        // @ts-ignore
        return new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0]).toISOString();
    }

    static getDateBy(date: any) {
        // 18/03/2021
        if(!date || ((typeof date) === 'object')) return date;
        else if(date.includes('/')) {
            const splitedDate = date.split('/');
            const [day, month, year] = splitedDate;
            return new Date(`${month}/${day}/${year}`);
        }
        else return date;
    }

    static getCurrentDate() {
        const date = new Date();
        return new Date(`${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`);
    }

    static isEqualDate(date: Date): boolean {
        return date.getTime() === this.getCurrentDate().getTime()
    }

    static toHumanDate(date: string, time?: string): string {
        if(!date) return '';
        // 18/03/2021, 12:41:00
        const dateSplit = date.split('/');
        // @ts-ignore
        const dateString = new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0]).toDateString();
        // Ex:: Sun Apr 18 2021
        const splitedDate = dateString.split(' ');

        const dayStr = splitedDate[0];
        const month = splitedDate[1];
        const dayNum = splitedDate[2];
        const year = splitedDate[3];

        let formatedTime = '';
        if(time) {
            const splitedTime = time.split(':');
            formatedTime = `${splitedTime[0]}:${splitedTime[1]}`;
        }

        return `${this.getALStringDay(dayStr)}, ${dayNum} ${this.getALStringMonth(month)} ${year} ${formatedTime}`;
    }

    static getALStringMonth = (month: string) => {
        switch (month.toLowerCase()) {
            case 'jan': return 'Janar';
            case 'feb': return 'Shkurt';
            case 'mar': return 'Mars';
            case 'apr': return 'Prill';
            case 'may': return 'Maj';
            case 'jun': return 'Qershor';
            case 'jul': return 'Korrik';
            case 'aug': return 'Gusht';
            case 'sep': return 'Shtator';
            case 'oct': return 'Tetor';
            case 'nov': return 'Nëntor';
            case 'dec': return 'Dhjetor';
        }
    }

    static getALStringDay = (day: string) => {
        switch (day.toLowerCase()) {
            case 'mon': return 'E Hënë';
            case 'tue': return 'E Martë';
            case 'wed': return 'E Mërkurë';
            case 'thu': return 'E Enjte';
            case 'fri': return 'E Premte';
            case 'sat': return 'E Shtunë';
            case 'sun': return 'E Dielë';
        }
    }

    static parseLanguageLocaleString = (locale: any): string => {
        const defaultLocale = "en";
        if(locale) {
            return locale;
        } else {
            return defaultLocale;
        }
    }

    static getCurrentNewDate() {
        const date = new Date();
        let day = (date.getDate().toString());
        let month = (date.getMonth() + 1).toString();
        let hours = (date.getHours()).toString();
        let minutes = (date.getMinutes()).toString();
        let seconds = (date.getSeconds()).toString();

        if (month.length === 1) {
            month = `0${month}`
        }
        if (day.length === 1) {
            day = `0${day}`
        }
        if (hours.length === 1) {
            hours = `0${hours}`
        }
        if (minutes.length === 1) {
            minutes = `0${minutes}`
        }
        if (seconds.length === 1) {
            seconds = `0${seconds}`
        }

        return `${day}/${month}/${date.getFullYear()} ${hours}:${minutes}:${seconds}`;
    }

}

export default DateUtils;