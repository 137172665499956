import React, {useEffect, useState} from "react";
import './AuthenticationDrawer.scss';
import {Link} from "react-router-dom";
import {Button, Form, Input} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {auth, setErrorMsg} from "../../store/auth";
import classNames from "classnames";
import {validateEmail} from "../../utils";
import {EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons";
import {AppState} from "../../store";

interface Props {
    onClose: any;
    onClickRegister: any;
    onClickForgotPassword: any;
}

interface State {
    password: string;
    email: string;
    showPassword: boolean;
}

const LoginDrawerContent = ({onClose, onClickRegister,onClickForgotPassword}: Props) => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const  loading = useSelector((state: AppState) => state.auth.loading);
    const  errorMsg = useSelector((state: AppState) => state.auth.errorMsg);
    const isDrawerOpen = useSelector((state: AppState) => state.auth.isDrawerOpen);

    const onFinish = (values: any) => {
        dispatch(auth(values,false,()=>{
        form.resetFields();
        }));
    };

    useEffect(() => {
        form.resetFields();
        dispatch(setErrorMsg(''))
    }, [isDrawerOpen]);

    const removeReadOnly = () => {
         // @ts-ignore
        document?.getElementById('emailInput').removeAttribute('readonly')
    }
    return (
        <div className="login-drawer-content">
            <h1>SIGN IN</h1>

            <Form form={form} name="control-hooks" onFinish={onFinish}  >
                <Form.Item name="email" label="Email" rules={[{ required: true, type: "email" }]} >
                    <Input id="emailInput" autoComplete="off"  readOnly={false} onFocus={removeReadOnly}  />
                </Form.Item>
                <Form.Item name="password" label="Password" rules={[{ required: true }]}>
                    <Input.Password
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
                       <div style={{width:'100%',display:'fex',textAlign:'center',padding:'10px',color:'red'}}>{errorMsg}</div>
                <Form.Item>
                    <a style={{float: 'right', marginTop: '10px'}} href=""  onClick={(e) => {
                        e.preventDefault();
                        onClickForgotPassword();
                    }}>
                        Forgot password
                    </a>
                </Form.Item>
                <Form.Item>
                    <div className="text-center mt-5">
                        <Button
                            htmlType="submit"
                            loading={loading}
                            className="button-primary">
                            Sign In
                        </Button>
                    </div>
                </Form.Item>
                <p className="mt-5 text-center">
                    Don't have an account?
                    <a href="#" className="pl-2"
                       onClick={(e) => {
                           e.preventDefault();
                           onClickRegister();
                       }}>Sign up</a>
                </p>

            </Form>
        </div>
    )
}

export default LoginDrawerContent;
