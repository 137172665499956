import React, {useEffect, useState} from "react";
import Header from "../../components/Header/Header";
import { Form, Input, InputNumber, Button } from 'antd';
import ContactUs from "../../assets/images/contact.png";
import Footer from "../../components/Footer/Footer";
import {useDispatch} from "react-redux";
import {ContactService} from "../../actions";
import {toast} from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {PhoneNumberTypes} from "../../types/service-types/PhoneNumberTypes";
import PhoneNumberService from "../../actions/phone-number.service";


/* eslint-disable no-template-curly-in-string */
const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};
/* eslint-enable no-template-curly-in-string */

const Contact = () =>{

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [text, setText] = useState("");

    const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumberTypes[]>();

    useEffect(() => {
        PhoneNumberService.fetchNumbers().then((res) => {
            setPhoneNumbers(res);
        })
    }, []);

    const onFinish = (values: any) => {
        setLoading(true);
        ContactService.contact(values)
            .then((res: any) => {
                setLoading(false);
                form.resetFields();
                toast.success(t("app.contact.success.message", "Thank you for contacting us!"));
            });
    };

    const countryNameTransform = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    return(
        <div>
            <div  className="contact-us">
                <h2>Contact Form</h2>
                <p><span><strong>Customer Care for customer support, bookings and information</strong></span></p>
                <p><span>For all questions and information, you can reach us by using the contact information below. Our friendly staff will be happy to give you all the answers you need.</span>
                </p>
                <p><span><strong>Customer Care for bookings and information</strong></span>
                    <div style={{display: 'grid'}}>
                        {phoneNumbers?.map(({countryName, phoneNumber}) => {
                            return (
                                <a href={`tel: ${phoneNumber}`} className="values-title">
                                    {countryNameTransform(countryName)}: {phoneNumber}
                                </a>
                            )
                        })}
                    </div>
                    <span>Email: info@prishtinajet.com</span>
                </p>
                <p><span>Working hours:</span><span className="s1">&nbsp;</span></p>
                <p><span>MON - SUN&nbsp;</span>
                    <span><strong>8:00&nbsp;a.m.</strong></span>
                    <span>&nbsp;to </span>
                    <span><strong>10:00&nbsp;p.m.</strong></span>
                    <span><br/> Please note that all times are in Central European Time (CET).</span>
                </p><br /> <br />
                <p>To contact us, please fill in the form below. We’ll make sure to handle the rest.</p>

            </div>
            <div className="contact-content">
                <div className="contact-form">
                    <Form form={form} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
                    <Form.Item name="fullName" label="Full Name" rules={[{ required: true, message: 'Please input your full name!' }]}>
                        <Input value={name} onChange={(e) => setName(e.target.value)} />
                    </Form.Item>
                    <Form.Item name="email" label="Email Address" rules={[{ type: 'email',required: true,message: 'Please input your email!' }]}>
                        <Input value={email} onChange={(e) => setEmail(e.target.value)} />
                    </Form.Item>
                    <Form.Item name="phoneNumber" label="Phone Number" rules={[{ required: true, message: 'Please input your phone number!' }]}>
                        <Input value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </Form.Item>
                    <Form.Item name="message" label="Message" rules={[{ required: true, message: 'Please input your message!' }]}>
                        <Input.TextArea style={{height: '96px'}} value={text} onChange={(e) => setText(e.target.value)} />
                    </Form.Item>
                    <Form.Item>
                        <Button disabled={loading} style={{width: '100%', marginTop: '24px', background:'#06A0B8', color: '#fff'}} htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
                <img className="image" src={ContactUs}/>
            </div>
            <Footer/>
        </div>
    )
}

export default Contact;
