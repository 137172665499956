import {createSlice, Dispatch} from '@reduxjs/toolkit';
import CountriesService from "../actions/airports.service";

export const fetchAirports = () => async (dispatch: Dispatch) => {
    const response = await CountriesService.getAirports();
    dispatch(setCountries(response));
}

interface Countries {
    countries: Array<any>;
}

const initialState: Countries = {
    countries: [],
}

const stateSlice = createSlice({
    name: 'home/countries',
    initialState,
    reducers: {
        setCountries: (state, action) => {state.countries = action.payload}
    }
});

export const {setCountries} = stateSlice.actions;

export default stateSlice.reducer;
