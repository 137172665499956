import "./MakePayment.scss";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {getQueryParameterByName} from "../../../utils";
import classNames from "classnames";
import Spinner from "../../../components/Spinner/Spinner";
import {ReservationService} from "../../../actions";
import BookFlightFooter from "../BookFlightFooter";
import MyBooking from "../../../components/MyBooking/MyBooking";
import BookingDetailsPassenger from "../../../components/BookingDetailsPassenger";
import Details from "../../../components/Details/Details";

const MakePayment = () => {
  const history = useHistory();
  const {t} = useTranslation();

  const departureAirport = getQueryParameterByName("from");
  const arrivalAirport = getQueryParameterByName("to");
  const departureDate = getQueryParameterByName("departure");
  const returnDate = getQueryParameterByName("return");
  const adults = getQueryParameterByName("adults");
  const children = getQueryParameterByName("children");
  const infants = getQueryParameterByName("infants");

  const [loading, setLoading] = useState(false);

  const [reservation, setReservation] = useState(null) as any;
  const [expandedRow, setExpandedRow] = useState<any>(null);
  let {reservationId} = useParams<any>();

  useEffect(() => {
    if (reservationId) {
      ReservationService.getReservationById(reservationId).then((res) => {
        setReservation(res);
      });
    }
  }, []);

  const nextStep = () => {
    history.push({
      pathname: "/booking/payment-methods",
      state: {
        from: "make-payment",
        debt: reservation?.debt + reservation?.currency?.symbol,
        reservationId
      }
    });
  };

  return (
      <div className="make-payment_container">
        <div className={classNames("make-payment", {["opacity-60"]: loading})}>
          {loading && <Spinner center/>}
          <h2 style={{marginBottom: "3.3rem"}}>
            {t("app.bookFlight.makePayment.title", "Make Payment")}
          </h2>
          <div className="row mt-5">
            <div className="col-12">
              {reservation && (
                  <MyBooking onClick={() => null} reservation={reservation}/>
              )}

              {reservation?.travelers.map((traveler: any, index: number) => (
                  <div className="booking-details-cards-container" key={index}>
                    <BookingDetailsPassenger
                        expanded={expandedRow === index}
                        passenger={traveler}
                        index={index}
                        onClick={() =>
                            setExpandedRow(expandedRow === index ? null : index)
                        }
                    />
                    <div
                        className={classNames("expandable-row", {
                          expanded: index === expandedRow,
                        })}
                    >
                      <Details traveler={traveler}/>
                    </div>
                  </div>
              ))}
            </div>
          </div>
        </div>
        <BookFlightFooter continue={nextStep} newSearchBtnHide={true}
                          totalPrice={reservation?.debt + reservation?.currency?.symbol}/>
      </div>
  );
};

export default MakePayment;
