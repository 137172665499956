import React, {useEffect, useState} from 'react'
import {PaymentService} from "../../actions";
import Braintree from "braintree-web-drop-in";

interface Props {
    onInstantiation: (error: any, instance: any) => void;
}

const PaymentForm = (props: Props) => {

    const [clientToken, setClientToken] = useState("");

    useEffect(() => {
        PaymentService.getClientToken(null).then(res => {
            setClientToken(res.clientToken);
        })
    }, []);

    useEffect(() => {
        if(clientToken) {
            Braintree.create({
                authorization: clientToken,
                container: '#payment-form'
            }, props.onInstantiation);
        }
    }, [clientToken]);

    return <div id="payment-form" />
}

export default PaymentForm;
