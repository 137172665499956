import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";
import store from "./store";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import './index.scss';
import {Toaster} from "react-hot-toast";
import {ConfigProvider} from "antd";
import locale from "antd/es/locale/en_GB"
import 'moment/locale/en-gb';
import moment from "moment";

moment.locale('en-gb', {
    week: {
        dow: 1 /// Date offset
    }
});

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <React.StrictMode>
                <Provider store={store}>
                    <BrowserRouter>

                        <Toaster
                            gutter={8}
                            reverseOrder={false}
                            position="top-center"
                            toastOptions={{
                                duration: 5000,
                                style: {
                                    color: '#374665',
                                    fontWeight: 'bold',
                                    fontSize: '18px'
                                }
                            }}
                        />

                        <ConfigProvider locale={locale}>
                            <App/>
                        </ConfigProvider>

                    </BrowserRouter>
                </Provider>
        </React.StrictMode>
    </I18nextProvider>,
    document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
