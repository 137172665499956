import "./ManualPaymentCard.scss";
import {ManualPayment} from "../ManualPayments";

type iProps = {
  payment: ManualPayment
  confirmationCode?: number
  debt?: string
  printButton: any
}

const ManualPaymentCard = ({payment, printButton, confirmationCode, debt}: iProps) => {
  return (
      <div className="manual-payment-container">
        <div className="content-container">
          <p>Payment {payment.country}</p>
          <p>Branch name: <span>{payment.name}</span></p>
          {payment.description && (
              <p>{payment.description}</p>
          )}
          {payment.address && (
              <p>Address: <span>{payment.address}</span></p>
          )}
          <p>Swift code: <span>{payment.bicSwift}</span></p>
          <p>Account Number: <span>{payment.IBAN}</span></p>
          {confirmationCode && (<p>Confirmation Code: <span>{confirmationCode}</span></p>)}
          {debt && (<p>Payment amount: <span>{debt}</span></p>)}
        </div>
        <div className="button-container">
          {printButton}
        </div>
      </div>
  );
};

export default ManualPaymentCard;