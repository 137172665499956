import {createSlice, Dispatch} from '@reduxjs/toolkit';
// import {Reservations} from '../types/service-types/ReservationsTypes'
import {ReservationsService} from "../actions";

export const fetchAllReservations = () => async (dispatch: Dispatch) => {
    const response = await ReservationsService.getReservations();
    dispatch(setReservations(response));
}

interface Reservations {
    reservations: Array<any>;
    reservationsFetched: boolean;
}

const initialState: Reservations = {
    reservations: [],
    reservationsFetched: false
}

const stateSlice = createSlice({
    name: 'reservations',
    initialState,
    reducers: {
        setReservations: (state, action) => {
            state.reservations = action.payload;
            state.reservationsFetched = true;
        }
    }
});

export const {setReservations} = stateSlice.actions;

export default stateSlice.reducer;
