import React, {useEffect} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import './App.scss';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import store, {AppState} from './store';
import Homepage from "./containers/Home/Home";
import BookFlight from "./containers/BookFlight/BookFlight";
import Bookings from "./containers/Bookings/Bookings";
import BookingDetails from "./containers/BookingDetails/BookingDetails";
import About from "./containers/About/About";
import Contact from "./containers/Contact/Contact";
import EditAccount from "./containers/Account/EditAccount";
import Header from "./components/Header/Header";
import {useHistory, useLocation} from "react-router";
import AuthenticationDrawer from "./components/AuthenticationDrawer/AuthenticationDrawer";
import {setDrawerContent, setLoginDrawer} from "./store/auth";
import Result from "./containers/Result";
import Account from "./containers/Account/Account";
import AuthRoute from "./components/Routes/AuthRoute";
import {fetchOffers} from "./store/offers";
// import axiosInterceptors from "./actions/setupInterceptors";
import TermsAndConditions from "./containers/Terms&Conditions/TermsAndConditions";
import ReactGa from "react-ga";
import ResetAccountPassword from "./containers/Account/ResetAccountPassword";
import {getQueryParameterByName} from "./utils";
import {possibleLanguages} from "./i18n";
import PrivacyPolicy from "./containers/PrivacyPolicy/PrivacyPolicy";
import DocumentMeta from "./components/DocumentMeta/DocumentMeta";
import Invoice from "./components/Invoice/Invoice";


// @ts-ignore
ReactGa.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS || 'UA-50424363-2');

function App() {
    const {i18n} = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    const isDrawerOpen = useSelector((state: AppState) => state.auth.isDrawerOpen);
    const accessToken = useSelector((state: AppState) => state.auth.accessToken);

    const language = getQueryParameterByName("language");



    useEffect(() => {
        ReactGa.pageview(window.location.pathname + window.location.search);
        dispatch(fetchOffers());
        const getLanguage = localStorage.getItem('language')
        if (getLanguage) {
            i18n.changeLanguage(getLanguage)
            localStorage.setItem('language', getLanguage);
            localStorage.setItem('i18nextLng', getLanguage);
        } else {
            localStorage.setItem('language', i18n.language);
            localStorage.setItem('i18nextLng', i18n.language);
        }

        if(language && possibleLanguages.includes(language)) {
            onLanguageChange(language);
        }
    }, [])

    const showHeader = location.pathname !== "/";

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, [history.location.pathname])

    const onLanguageChange = (lang: any) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('language', lang);
        localStorage.setItem('i18nextLng', lang);
    }

    return (
        <div>
            <DocumentMeta/>
            <Header/>
            <AuthenticationDrawer isDrawerOpen={isDrawerOpen} onClose={() => {
                dispatch(setLoginDrawer(false))
                dispatch(setDrawerContent('login'));

            }} />
            <Switch>
                <Route exact path="/" component={Homepage}/>
                <Route path="/booking" component={BookFlight}/>

                <Route path="/handlers/payments" component={Result}/>

                <AuthRoute exact path="/bookings" component={Bookings} authenticated={!!accessToken} />
                <Route path="/bookings/:reservationId" component={BookingDetails} />

                <AuthRoute exact path="/account" component={Account} authenticated={!!accessToken} />
                <AuthRoute path="/account/edit" component={EditAccount} authenticated={!!accessToken} />
                <Route path="/account/reset-password/:key" component={ResetAccountPassword} />

                <Route path="/about" component={About}/>
                <Route path="/contact" component={Contact}/>

                <Route path="/terms&conditions" component={TermsAndConditions}/>

                <Route path="/privacy-policy" component={PrivacyPolicy} />

                {/*<Route path="/flights" component={Flights}/>*/}
                {/*<Route path="/information" component={Information}/>*/}
                {/*<Route path="/special-offers" component={SpecialOffers}/>*/}
                {/*<Route path="/appointment" component={Appointment}/>*/}

            </Switch>
        </div>

    );
}


export default withRouter(App);
