import React from 'react'
import styles from "./HomepagePassengersDropdown.module.scss";
import {ReactComponent as CloseIcon} from "../../assets/images/close.svg";
import {Button, Input} from "antd";
import {useTranslation} from "react-i18next";
import {UserOutlined} from "@ant-design/icons";

interface Props {
    adults: number;
    adultsDisabled: boolean;
    onAdultsIncrease: any;
    onAdultsDecrease: any;

    children: number;
    childrenDisabled: boolean;
    onChildrenIncrease: any;
    onChildrenDecrease: any;

    infants: number;
    infantsDisabled: boolean;
    onInfantsIncrease: any;
    onInfantsDecrease: any;

    onClose: any;
    onCancel: any;
}


const HomepagePassengersDropdown = (props: Props) => {

    const {t} = useTranslation();
  const reachedMaximum = (props.adults + props.children ) === 10;
    return (
        <div className={styles.homepagePassengersDropdown}>
            <h3>Passenger selection</h3>
            <div className={styles.passengerDropdown}>
                <div className={styles.title}>

                    {t("app.home.form.passengers.adults.label", "Adults")}
                  <span> (14+)</span>
                    <br></br>
                </div>
                <div className={styles.row}>
                    <button disabled={props.adultsDisabled} onClick={props.onAdultsDecrease}>-</button>
                    <input type="number" value={props.adults} readOnly/>
                    <button onClick={props.onAdultsIncrease} disabled={reachedMaximum}>+</button>
                </div>
            </div>
            <div className={styles.passengerDropdown}>
                <div className={styles.title}>
                    {t("app.home.form.passengers.children.label", "Children")}
                  <span> (2-14)</span>
                  <br></br>
                </div>
                <div className={styles.row}>
                    <button disabled={props.childrenDisabled} onClick={props.onChildrenDecrease}>-</button>
                    <input type="number" value={props.children} readOnly/>
                    <button onClick={props.onChildrenIncrease} disabled={reachedMaximum}>+</button>
                </div>
            </div>
            <div className={styles.passengerDropdown}>
            <div className={styles.title}>
                    {t("app.home.form.passengers.babies.label", "Babies")}
              <span> (0-2)</span>
              <br></br>
                </div>
                <div className={styles.row}>
                    <button disabled={props.infantsDisabled} onClick={props.onInfantsDecrease}><span>-</span></button>
                    <input type="number" value={props.infants} readOnly/>
                    <button onClick={props.onInfantsIncrease} disabled={props.adults <= props.infants}>+</button>
                </div>
            </div>
          <p>The infant should be at least 14 days and less than
            2 years old on the day of the flight.</p>
        </div>
    )

}

export default HomepagePassengersDropdown;
