import axios from './axios.interceptors';

class CountriesService {
    public getAirports = async (): Promise<any[]> => {
        try {
            const response = await axios.get('/api/v1/countries/airports');
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }
}

export default new CountriesService();
