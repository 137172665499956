// general utilities

export const getQueryParameterByName = (name: string, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return '';
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const preparePassengersLabel = (options: any) => {
    let adultsLabel = prepareLabel(parseInt(options.adults), options.adultsSingularLabel, options.adultsPluralLabel);
    let childrenLabel = prepareLabel(parseInt(options.children), options.childrenSingularLabel, options.childrenPluralLabel);
    let infantsLabel = prepareLabel(parseInt(options.infants), options.babiesSingularLabel, options.babiesPluralLabel);
    const label = `${adultsLabel}${options.children ? ", " : ""}${childrenLabel}${options.infants ? ", " : " "}${infantsLabel}`
    return label;
}

export const prepareLabel = (count: number, textSingular: string, textPlural: string) => {
    let label = "";
    if(count) {
        label = `${count} ${count < 2 ? textSingular : textPlural}`;
    }
    return label;
}

export const validateEmail = (email: any) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function addToLSObject(lsObjName: string, attr: string, val: any) {
    const lsObj = JSON.parse((window as any).localStorage.getItem(lsObjName)) || {};

    lsObj[attr] = val;

    const userDataWI = JSON.stringify(lsObj);

    (window as any).localStorage.setItem(lsObjName, userDataWI)

};

export const parseJwt = (token: string) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};
