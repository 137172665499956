import React, {useState} from 'react'
import styles from "./Checkin.module.scss";
import Group2 from "../../assets/images/Group 2.svg";
import Group4 from "../../assets/images/Group 4.svg";
import Group3 from "../../assets/images/Group 3.svg";
import Group5 from "../../assets/images/Group 5.svg";
import {Divider} from "antd/es";

const Checkin = () => {
  return (
      <div className={styles.benefits}>
        <h1>Benefits of booking with us</h1>
        <div className={styles.benefit}>
          <div style={{marginRight: "8rem"}}>
            <div style={{display: 'flex', marginBottom: '45px'}}>
              <img src={Group2}/>
              <div>
                <h2>Simplified Booking Experience</h2>
                <p>Feel the flexibility and simplicity throughout
                  your booking process</p>
              </div>
            </div>
            <Divider />
          </div>
          <div>
            <div style={{display: 'flex', marginBottom: '45px'}}>
              <img src={Group4}/>
              <div>
                <h2>Seat Reservation</h2>
                <p>Feel the flexibility and simplicity throughout
                  your booking process</p>
              </div>
            </div>
            <Divider />
          </div>
        </div>

        <div className={styles.benefit}>
          <div style={{marginRight: "8rem"}}>
            <div style={{display: 'flex', marginTop: '-20px'}}>
              <img src={Group3}/>
              <div>
                <h2>Exclusive Offer Everyday</h2>
                <p>Feel the flexibility and simplicity throughout
                  your booking process</p>
              </div>
            </div>
          </div>
          <div>
            <div style={{display: 'flex', marginTop: '-20px'}}>
              <img src={Group5}/>
              <div>
                <h2>Fast Customer Support</h2>
                <p>Feel the flexibility and simplicity throughout
                  your booking process</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Checkin;

