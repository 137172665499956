import axios from './axios.interceptors';
import {FlightBody} from "../types/service-types/FlightTypes";

class SelectedFlightServices {
    public getSelectedFlight = async (body: any): Promise<FlightBody> => {
        try {
            const response = await axios.get('api/v1/flights', {params: body});
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    public previewPrice = async (body: any): Promise<any> => {
        try {
            const response = await axios.get('/api/v1/reservations/previewPrice', {params: body});
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }
}

export default new SelectedFlightServices();
