import axios from "./axios.interceptors";
import {DatesRequestBody} from "../types/service-types/BookingTypes";

class AvailableDatesService {
    public getDates = async (body: any): Promise<DatesRequestBody> => {
        try {
            const response = await axios.get('/api/v1/flights/available', {params: body});
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }
}

export default new AvailableDatesService();

