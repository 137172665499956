import React from 'react';
import styles from "./SuccessResult.module.scss";
import {ReactComponent as SuccessIcon} from "../../assets/images/success.svg";
import {Button} from "antd";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import ManualPaymentsComponent from "../ManualPayments/ManualPaymentsComponent";

const SuccessResult = (props: any) => {

  const {t} = useTranslation();

  return <div className={styles.successResult}>
    <SuccessIcon/>

    <p className="mt-5">{t("app.bookFlight.result.success.title", "Congratulations!")}</p>

    <p>{t("app.bookFlight.result.success.subtitle", "Your flight has been booked.")}</p>

    <div className={styles.successResultButtons}>
      <Button
          htmlType="button"
          onClick={props.onBookingDetailsButtonClick}
          className="button-primary mb-3">
        {t("app.bookFlight.result.success.buttons.details", "View booking details")}
      </Button>
      <Button
          htmlType="button"
          onClick={props.onHomeButtonClick}
          className="button-outline">
        {t("app.bookFlight.result.success.buttons.home", "Home")}
      </Button>
    </div>
    {props.isManualPaymentShown &&
        <ManualPaymentsComponent confirmationCode={props.confirmationCode} debt={props.debt}/>}
  </div>
}


export default SuccessResult;
