import React from "react";
import styles from "./BookingDetailsPassenger.module.scss";
import {ReactComponent as Arrow} from "../../assets/images/arrow-up.svg";
import {PassengerType} from "../PassengerFormRow/PassengerFormRow";
import classNames from "classnames";

interface Props {
    passenger: any;
    index: number;
    expanded: boolean;
    onClick: any;
}

const BookingDetailsPassenger = ({passenger, index, expanded, onClick}: Props) => {
    const type = passenger.ageCategory == "INF" ? "INFANT" : passenger.ageCategory;
    return (
        <div className={styles.passenger} onClick={onClick}>
            <div className={styles.content}>
                <div className={styles.subtitle}>
                    Passenger {index + 1} - {type}
                </div>
                <div className={styles.title}>
                    {passenger.name} {passenger.surname}
                </div>
            </div>
            <div className={classNames(styles.arrow, {[styles.rotate]: expanded})}>
                <Arrow/>
            </div>
        </div>
    )
}

export default BookingDetailsPassenger;
