import React, {useEffect, useRef, useState} from "react";
import "./Passengers.scss";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import {useHistory} from "react-router-dom";
import {getQueryParameterByName, preparePassengersLabel,} from "../../../utils";
import PassengerFormRow, {
  PassengerType,
} from "../../../components/PassengerFormRow/PassengerFormRow";
import {setPassengers} from "../../../store/passengers";
import {useLocation} from "react-router";
import {ReactComponent as Arrow} from "../../../assets/images/right-arrow.svg";
import {useTranslation} from "react-i18next";
import BookFlightFooter from "../BookFlightFooter";
import {fetchPricePreview} from "../../../store/selectedFlights";

interface Props {
  onRefreshTimeout: any;
}

const Passengers = ({onRefreshTimeout}: any) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const PassengerFormRowRef = useRef() as any;
  const env = process.env.REACT_APP_ENVIRONMENT;

  const [isValidationVisible, setIsValidationVisible] = useState(false);

  const accessToken = useSelector((state: AppState) => state.auth.accessToken);
  const isLoginDrawerOpen = useSelector(
      (state: AppState) => state.auth.isDrawerOpen
  );

  const departureFlight = useSelector(
      (state: AppState) => state.selectedFlights.departureFlight
  );

  const returnFlight = useSelector(
      (state: AppState) => state.selectedFlights.returnFlight
  );

  const passengers = useSelector(
      (state: AppState) => state.passengers.passengers
  );

  const price = useSelector(
      (state: AppState) => state.selectedFlights.pricePreview
  );

  const filteredPrice = price?.toString().split(/(\s+)/).filter((val: any) =>
      val.trim().length > 0
  );

  const departureAirport = getQueryParameterByName("from");
  const arrivalAirport = getQueryParameterByName("to");
  const departureDate = getQueryParameterByName("departure");
  const returnDate = getQueryParameterByName("return");
  const adults = getQueryParameterByName("adults");
  const children = getQueryParameterByName("children");
  const infants = getQueryParameterByName("infants");
  const source = getQueryParameterByName("source");
  const language = getQueryParameterByName("language");

  let timeout: any = null;
  useEffect(() => {
    if (!departureFlight || !adults) {
      history.push({
        pathname: "/booking/flights",
        search: `?from=${departureAirport}&to=${arrivalAirport}&departure=${departureDate}${
            returnDate ? "&return=" + returnDate : ""
        }&adults=${adults}${children ? "&children=" + children : ""}${
            infants ? "&infants=" + infants : ""
        }${source ? "&source=" + source : ""}${language ? "&language=" + language : ""}`,
      });
    }
    if (departureFlight?.id) {
      const priceParams = {
        numberOfAdults: adults || 0,
        numberOfChildren: children || 0,
        numberOfInfants: infants || 0,
        departureFlightId: departureFlight.id,
        returnFlightId: returnFlight?.id,
      };
      dispatch(fetchPricePreview(priceParams));
    }
  }, []);

  useEffect(() => {
    if (source === 'skyscanner' || source === 'skyscanner_mob') {
      if (env === 'PROD') {
        // Initialise SAT with your Tracking Source ID
        (window as any).sat('init', 'SAT-959765-1');

        // Send a 'landing' event
        (window as any).sat('send', 'landing', {
          priceSeen: filteredPrice[0],
          currency: filteredPrice[1]
        });
      }
    }
  }, [price]);

  useEffect(() => {
    const redirect = getQueryParameterByName("redirect");
    if (!isLoginDrawerOpen && accessToken && redirect) {
      nextStep();
    }
  }, [isLoginDrawerOpen, accessToken]);

  // initialize traveller objects on component did mount
  useEffect(() => {
    console.log();
    const isFormValid =
        passengers.every(
            (passenger: any) =>
                passenger.name?.trim() &&
                passenger.surname?.trim() &&
                passenger.gender &&
                passenger.dateOfBirth
        ) && passengers.length;
    const sameAdults =
        passengers.filter(
            (passenger: any) => passenger.ageCategory === PassengerType.ADULT
        ).length === adults;
    const sameChildren =
        passengers.filter(
            (passenger: any) => passenger.ageCategory === PassengerType.CHILD
        ).length === children;
    const sameInfants =
        passengers.filter(
            (passenger: any) => passenger.ageCategory === PassengerType.INFANT
        ).length === infants;
    if (!isFormValid || !sameAdults || !sameChildren || !sameInfants) {
      let newPassengers = [];
      if (adults)
        newPassengers.push(
            [...Array(parseInt(adults)).keys()].map((i) =>
                traveller(PassengerType.ADULT)
            )
        );
      if (children)
        newPassengers.push(
            [...Array(parseInt(children)).keys()].map((i) =>
                traveller(PassengerType.CHILD)
            )
        );
      if (infants)
        newPassengers.push(
            [...Array(parseInt(infants)).keys()].map((i) =>
                traveller(PassengerType.INFANT)
            )
        );
      dispatch(setPassengers(newPassengers.flat()));
    }
  }, []);

  const traveller = (type: PassengerType) => {
    return {
      ageCategory: type,
      name: "",
      surname: "",
      dateOfBirth: "",
      gender: "",
      departureFlightId: "",
      returnFlightId: "",
    };
  };

  const nextStep = () => {
    dispatch(
        setPassengers(
            passengers.map((passenger: any) => ({
              ...passenger,
              departureFlightId: departureFlight.id,
              returnFlightId: returnFlight ? returnFlight.id : null,
            }))
        )
    );

    setIsValidationVisible(true);
    const isFormValid = passengers.every(
        (passenger: any) =>
            passenger.name?.trim() &&
            passenger.surname?.trim() &&
            passenger.gender &&
            passenger.dateOfBirth &&
            !PassengerFormRowRef?.current.dateNotValid()
    );
    if (!isFormValid) {
      return;
    }

    history.push({
      pathname: "/booking/overview",
      search: `?from=${departureAirport}&to=${arrivalAirport}&departure=${departureDate}${
          returnDate ? "&return=" + returnDate : ""
      }&adults=${adults}${children ? "&children=" + children : ""}${
          infants ? "&infants=" + infants : ""
      }${source ? "&source=" + source : ""}${language ? "&language=" + language : ""}`,
    });
  };

  const onPassengerChange = (passenger: any, index: number) => {
    let newPassengers = [...passengers];
    newPassengers[index] = passenger;
    dispatch(setPassengers(newPassengers));
  };
  const onGoBackClick = () => {
    history.push({
      state: {
        backFromPassengers: true,
      },
      pathname: "/booking/flights",
      search: `?from=${departureAirport}&to=${arrivalAirport}&departure=${departureDate}${
          returnDate ? "&return=" + returnDate : ""
      }&adults=${adults}${children ? "&children=" + children : ""}${
          infants ? "&infants=" + infants : ""
      }${source ? "&source=" + source : ""}${language ? "&language=" + language : ""}`,
    });
  };


  return (
      <div className="flights-container">
        <div className="flights-padding-container">
          <div className="flights-page-back">
            <div className="flights-back-arrow" onClick={onGoBackClick}>
              <Arrow/>
              {t("app.bookFlight.header.goBack", "Go back")}
            </div>
          </div>
          <div className="flights-page-header">
            <div className="title">
              {t("app.bookFlight.passengers.title", "Passengers")}:
            </div>
            <div className="subtitle">
              {preparePassengersLabel({
                adults,
                children,
                infants,
                adultsSingularLabel: t(
                    "app.home.form.passengers.label.adults.singular"
                ),
                childrenSingularLabel: t(
                    "app.home.form.passengers.label.children.singular"
                ),
                babiesSingularLabel: t(
                    "app.home.form.passengers.label.babies.singular"
                ),
                adultsPluralLabel: t(
                    "app.home.form.passengers.label.adults.plural"
                ),
                childrenPluralLabel: t(
                    "app.home.form.passengers.label.children.plural"
                ),
                babiesPluralLabel: t(
                    "app.home.form.passengers.label.babies.plural"
                ),
              })}
            </div>
          </div>

          {passengers.map((passenger: any, index: number) => (
              <PassengerFormRow
                  ref={PassengerFormRowRef}
                  isValidationVisible={isValidationVisible}
                  key={`passenger-form-row-${index}`}
                  type={passenger.ageCategory}
                  passenger={passenger}
                  onChange={(passenger: any) => onPassengerChange(passenger, index)}
              />
          ))}
        </div>

        <BookFlightFooter continue={nextStep}/>
      </div>
  );
};

export default Passengers;
