import React, {useEffect, useState} from "react";
import './Account.scss'
import Pic from './../../assets/images/stuttgart-card.png'
import {Button, DatePicker, Divider, Form, Input, message, Modal, Select} from 'antd';
import {useHistory} from "react-router";
import {useDispatch} from "react-redux";
import {resetPassword} from "../../store/resetPassword";
import {fetchAccountDetails} from "../../store/account-info.slice";
import moment from "moment";
import {ReactComponent as Arrow} from "../../assets/images/arrow-left.svg";
import SideOffers from "../../components/SideOffers";
import {updateAccountInfo} from "../../store/account-update-info";
import {toast} from "react-hot-toast";
import {useTranslation} from "react-i18next";

const {Option} = Select;

const EditAccount = () => {

    const {t} = useTranslation();
    const history = useHistory();
    const dispatch: any = useDispatch();
    const [form] = Form.useForm();
    const [changePasswordForm] = Form.useForm();

    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const [initialAccountData, setInitialAccountData] = useState<any>();
    const [accountData, setAccountData] = useState<any>();

    function resetSuccess () {
        message.success('Password is changed successfully!');
        changePasswordForm.resetFields();
        setVisible(false)
    }

    useEffect(() => {
        dispatch(fetchAccountDetails())
            .then((res: any) => {
                const newRes = {...res};
                delete newRes.reservations;
                delete newRes.zipCode;
                delete newRes.id;
                delete newRes.address;
                setInitialAccountData(newRes);
                setAccountData(newRes);
                form.setFieldsValue({
                    ...newRes,
                    dateOfBirth: newRes.dateOfBirth ? moment(newRes.dateOfBirth, "DD/MM/YYYY") : null
                })
            });
    }, []);

    const showModal = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setConfirmLoading(true);

    };

    const handleCancel = () => {
        changePasswordForm.resetFields();
        setVisible(false);

    };

    const onFinish = (values: any) => {
        const newValues = {
            ...values,
            dateOfBirth: moment(values.dateOfBirth).format("DD/MM/YYYY")
        }
        dispatch(updateAccountInfo(newValues))
            .then(() => {
                toast.success("Account updated successfully!")
                history.goBack();
            });
    }

    const isSubmitButtonDisabled = () => {
        if(!accountData || !initialAccountData) {
            return true;
        }
        let isDisabled = true;
        for(let key in initialAccountData) {
            if(accountData[key] !== initialAccountData[key]) {
                isDisabled = false;
            }
        }
        return isDisabled;
    }

    return (
        <div className="account-container">

            <div className="d-flex justify-content-between mb-5">
                <div className="account-title"
                     onClick={() => history.push("/account")}>
                    <Arrow/> {t("app.account.editAccount.profile", "Profile")}
                </div>
            </div>

            <div className="row">
                <div className="col-md-12 col-lg-8">

                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <div className="account-image">
                                <img src={Pic}/>
                            </div>
                        </div>
                        <div className="col-12 col-lg-7">

                            <Form
                                form={form}
                                name="control-hooks"
                                onFinish={onFinish}
                                onFieldsChange={(_, allFields) => {
                                    const values = form.getFieldsValue();
                                    setAccountData({
                                        ...values,
                                        dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth).format("DD/MM/YYYY") : null
                                    });
                                }}>
                                <p className="account-section mt-0">{t("app.account.editAccount.accountDetails", "ACCOUNT DETAILS")}</p>

                                <Form.Item name="fullName" className="details" label={<label className="details">{t("app.account.editAccount.fullName", "Full Name")}</label>}
                                           rules={[{required: true, message: 'Please input your Full Name!'}, {pattern: /^[aA-zZ\s]+$/, message: 'Only alphabets are allowed for this field'}]}>
                                    <Input/>
                                </Form.Item>

                                <Form.Item name="email" className="details" label={<label className="details">{t("app.account.editAccount.email", "Email Address")}</label>} rules={[{type: 'email', message: 'The input is not valid E-mail!'}, {required: true, message: 'Please input your E-mail!'},]}>
                                    <Input/>
                                </Form.Item>

                                <Form.Item name="password" label={t("app.account.editAccount.password", "Password")}>
                                    <Input.Password disabled={true}/>
                                </Form.Item>

                                <Button
                                    onClick={showModal}
                                    className="button-outline w-100 mt-4">
                                    {t("app.account.editAccount.changePassword", "Change password")}
                                </Button>

                                <p className="account-section">{t("app.account.editAccount.contactDetails", "CONTACT DETAILS")}</p>

                                <Form.Item name="phoneNumber" className="details" label={<label className="details">{t("app.account.editAccount.phoneNumber", "Phone Number")}</label>} rules={[{required: true, message: 'Please input your phone number!'}, {pattern: /^\+(?:[0-9]●?){6,14}[0-9]$/gm, message: 'Contact number must start with + sign'}]}>
                                    <Input/>
                                </Form.Item>


                                <p className="account-section">{t("app.account.editAccount.personalDetails", "PERSONAL DETAILS")}</p>
                                <div className='row'>
                                    <div className="col p-0">
                                        <Form.Item name="dateOfBirth" className="details" label={<label className="details">{t("app.account.editAccount.dateOfBirth", "Date of birth")}</label>} rules={[{required: true, message: 'Please select date of birth!'}]}>
                                            <DatePicker format="DD/MM/YYYY" disabledDate={(date:any)=>{
                                                return new Date(date) > new Date()
                                            }}/>
                                        </Form.Item>
                                    </div>
                                    <div className="col p-0">
                                        <Form.Item name="gender" className="details" label={<label className="details">{t("app.account.editAccount.gender", "Gender")}</label>} rules={[{required: true, message: 'Please select gender!'}]}>
                                            <Select placeholder={t("app.account.editAccount.placeholder.selectGender", "Select Gender")}>
                                                <Option value="MALE">{t("app.account.editAccount.gender.male", "Male")}</Option>
                                                <Option value="FEMALE">{t("app.account.editAccount.gender.female", "Female")}</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>

                                <Form.Item name="city" className="details" label={<label className="details">{t("app.account.editAccount.city", "City")}</label>} rules={[{required: true, message: 'Please input your City!'}, {pattern: /^[aA-zZ\s]+$/, message: 'Only alphabets are allowed for this field'}]}>
                                    <Input/>
                                </Form.Item>

                                <Form.Item name="country" className="details" label={<label className="details">{t("app.account.editAccount.country", "Country")}</label>}  rules={[{required: true, message: 'Please input your Country!'}, {pattern: /^[aA-zZ\s]+$/, message: 'Only alphabets are allowed for this field'}]}>
                                    <Input/>
                                </Form.Item>

                                <Divider/>
                                <div className="d-flex justify-content-end mb-5">
                                    <Button
                                        className="button-text-primary"
                                        onClick={() => history.push("/account")}>
                                        {t("app.account.editAccount.button.cancel", "Cancel")}
                                    </Button>
                                    <Button
                                        disabled={isSubmitButtonDisabled()}
                                        htmlType="submit"
                                        className="button-primary">
                                        {t("app.account.editAccount.button.save", "Save")}
                                    </Button>
                                </div>

                            </Form>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 col-lg-4 right-column">
                    <SideOffers/>
                </div>
            </div>

            <Modal
                closable={false}
                title= {t("app.account.editAccount.changePassword", "Change password")}
                visible={visible}
                confirmLoading={confirmLoading}
                width='406px'
                footer={null}
                maskStyle={{backgroundColor: 'rgba(13, 59, 102, 0.6)'}}>

                <Form className='form-style'
                      form={changePasswordForm}
                      initialValues={{oldPassword: '', newPassword: ''}}
                      onFinish={({oldPassword, newPassword}) =>  {
                          dispatch(resetPassword({ body: {oldPassword, newPassword}, onSuccess: resetSuccess}));
                      }}>

                    <Form.Item
                        name="oldPassword"
                        label={t("app.account.editAccount.oldPassword", "Old Password")}
                        rules={[{ required: true, message: 'Please input your old password!'}]}>
                        <Input.Password/>
                    </Form.Item>

                    <Form.Item
                        name="newPassword"
                        label={t("app.account.editAccount.newPassword", "New Password")}
                        rules={[
                            {required: true, message: 'Please input your password!',},
                            {pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, message: 'Your new password must contain uppercase/lowercase letters, characters and numbers!'}
                        ]}>
                        <Input.Password/>
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        label={t("app.account.editAccount.confirmNewPassword", "Confirm New Password")}
                        dependencies={['newPassword']}
                        rules={[{required: true, message: 'Please confirm your password!'},
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}>
                        <Input.Password/>
                    </Form.Item>

                    <div style={{display: 'flex', justifyContent:'end', marginTop: "2rem"}}>
                        <Button className="button-text-primary" onClick={handleCancel}> {t("app.account.editAccount.button.cancel", "Cancel")}</Button>
                        <Button htmlType="submit" className="button-primary" onClick={handleOk}> {t("app.account.editAccount.button.save", "Save")}</Button>
                    </div>
                </Form>
            </Modal>

            </div>
    )
}

export default EditAccount;
