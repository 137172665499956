import axios from './axios.interceptors';
import {OffersBody} from "../types/service-types/OffersTypes"

class OffersService {
    public getOffers = async (): Promise<OffersBody[]> => {
        try {
            const response = await axios.get('/api/v1/flights/offers');
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }
}

export default new OffersService();
