import "./PaymentMethods.scss";
import creditCard from "../../../assets/images/credit-card.jpg";

export interface PaymentCardProps {
  logo: any;
  description: string;
  onClick: () => void;
  logoVisa?: any | null;
  logoMaster?: any;
}

const PaymentCard = (props: PaymentCardProps) => {
  return (
      <div className="payment-card flex-column" onClick={props.onClick}>
        <div className="payment-card_logo-wrapper">
          <img alt="card" className="payment-card_card" src={creditCard}/>
          <img src={props.logo} className="payment-card_logo" alt="logo"/>
        </div>

        <div className="payment-card_text">{props.description}</div>
        {props.logoVisa && props.logoMaster ?
            <div className="app-text-primary payment-card-pay-now" style={{textAlign: 'end'}}>
              Pay Now!
              {props.logoMaster
                  ?
                  <img src={props.logoMaster} style={{height: "15px", marginLeft: "20px"}} className="payment-card_logo"
                       alt="logo"/>
                  :
                  <span></span>
              }
              {props.logoVisa
                  ?
                  <img src={props.logoVisa} style={{height: "15px"}} className="payment-card_logo"
                       alt="logo"/>
                  :
                  <span></span>
              }
            </div>
            :
            <div className="app-text-primary payment-card-pay-now">Pay Now!</div>
        }


      </div>
  );
};

export default PaymentCard;
