import axios from "./axios.interceptors";

class PaymentService {
  public getClientToken = async (body: any): Promise<any> => {
    const accessToken = localStorage.getItem("pr_jet_access_token");
    try {
      const response = await axios.get("/api/v1/payments/client-token", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  public checkout = async (body: any): Promise<any> => {
      const accessToken = localStorage.getItem("pr_jet_access_token");
    try {
      const response = await axios.post(`/api/v1/reservations/checkout`, body,{
        headers: {
          Authorization: accessToken && `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  public requestPayment = async (id:string,type:string): Promise<any> => {
    try {
      const response = await axios.post(`/api/reservations/${id}/request-payment`, {type});
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
}

export default new PaymentService();
