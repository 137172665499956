import axios from 'axios';
import {toast} from "react-hot-toast";
import {AuthService} from './'

const baseUrl = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL: baseUrl || "https://api.prjet.linkplus-it.com",
  headers: {
    "Content-Type": "application/json",
  },
});

instance.defaults.headers.common.Accept = "application/json";
// Add a request interceptor

instance.interceptors.response.use(
    function (response) {
      // If the request succeeds, we don't have to do anything and just return the response
      return response;
    },
    function (error) {
      const errorResponse = error.response;
      if (isTokenExpiredError(errorResponse)) {
        return resetTokenAndReattemptRequest(error);
      } else {
        // If the error is due to other reasons, we just throw it back to axios
        return Promise.reject(error.response);
      }
    }
);

function isTokenExpiredError(errorResponse: any) {
  return !!(
      errorResponse.data.error_description &&
      errorResponse.data.error_description.includes("Access token expired")
  );
}

let isAlreadyFetchingAccessToken = false;

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers: any[] = [];

function resetTokenAndReattemptRequest(error: any) {
  try {
    const {response: errorResponse} = error;
    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber((access_token: any) => {
        errorResponse.config.headers = {
          Authorization: access_token ? `Bearer ${access_token}` : null,
          "Content-Type": "application/json",
        };
        resolve(instance(errorResponse.config));
      });
    });
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      AuthService.refresh_token()
      .then((response: any) => {
        // @ts-ignore
        const newToken = response.data.access_token;
        // @ts-ignore

        if (response.data.access_token && response.data.refresh_token) {
          localStorage.setItem("pr_jet_access_token", response.data.access_token);
          localStorage.setItem("pr_jet_refresh_token", response.data.refresh_token);


        } else {
          localStorage.removeItem("pr_jet_access_token");
          localStorage.removeItem("pr_jet_refresh_token");
        }
        isAlreadyFetchingAccessToken = false;
        onAccessTokenFetched(newToken);
      })
      .catch((error: any) => {
        console.log(error);
        return Promise.reject(error);
      });
    }
    return retryOriginalRequest;
  } catch (err) {
    console.log(error);
    return Promise.reject(err);
  }
}

function addSubscriber(callback: (access_token: any) => void) {
  subscribers.push(callback);
}

function onAccessTokenFetched(access_token: any) {
  // When the refresh is successful, we start retrying the requests one by one and empty the queue
  subscribers.forEach((callback) => callback(access_token));
  subscribers = [];
}

// Add a request interceptor
instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("pr_jet_access_token");
      if (token && !isAlreadyFetchingAccessToken) {
        // Do something before request is sent
        config.withCredentials = false;
        config.headers = {
          Authorization: token ? `Bearer ${token}` : null,
          "Content-Type": "application/json",
        };
      }

      return config;
    },
    function (error) {
      // Do something with request error
      // console.error("Response Error ", error);
      if (error.response.status !== 401) {
        console.log(error.response);
        const errorMessage = error.response?.data.error_description || error.response?.data.message;
        if (errorMessage !== 'Bad credentials') toast.error(errorMessage);

      }
      console.log(error);
      return Promise.reject(error);
    }
);

export default instance;
