import axios from './axios.interceptors';
import {FlightPriceBody} from "../types/service-types/FlightPriceTypes";
import {FlightBody} from "../types/service-types/FlightTypes";

class FlightsService {
    public getWeeklyFlights = async (body: any): Promise<Array<FlightPriceBody>> => {
        try {
            const response = await axios.get('api/v1/flights/weekly', {params: body});
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    public getPreviousAvailableFlights = async (body: any): Promise<Array<FlightPriceBody>> => {
        try {
            const response = await axios.get('api/v1/flights/weekly/previous', {params: body});
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    public getNextAvailableFlights = async (body: any): Promise<Array<FlightPriceBody>> => {
        try {
            const response = await axios.get('api/v1/flights/weekly/next', {params: body});
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    public getDailyFlights = async (body: any): Promise<FlightBody> => {
        try {
            const response = await axios.get('api/v1/flights', {params: body});
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    public getFlightById = async (id: any): Promise<any> => {
        try {
            const response = await axios.get('api/v1/flights/' + id);
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }
}

export default new FlightsService();
