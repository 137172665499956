import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {FlightPriceBody} from "../types/service-types/FlightPriceTypes";
import {FlightPriceService} from "../actions";

export const fetchWeeklyFlights = (body: any) => async(dispatch: Dispatch) => {
    setLoading(true);
    const response = await FlightPriceService.getWeeklyFlights(body);
    dispatch(weeklyFlights(response));
    setLoading(false);
    return response;
}

export const setWeeklyFlights = (body: any) => async(dispatch: Dispatch) => {
    dispatch(weeklyFlights(body));
}

interface FlightPriceSlice {
    weeklyFlights: Array<any>;
    loading: boolean;
}

const initialState: FlightPriceSlice = {
    weeklyFlights: [],
    loading: false
}

const stateSlice = createSlice({
    name: 'bookflight/flight-price',
    initialState,
    reducers: {
        setLoading: (state, action) => {state.loading = action.payload},
        weeklyFlights: (state, action) => {state.weeklyFlights = action.payload}
    }
});

export const {setLoading, weeklyFlights} = stateSlice.actions;

export default stateSlice.reducer;
