import React, {useEffect, useState} from "react";
import {useLocation} from "react-router";
import {getQueryParameterByName} from "../../utils";
import {useHistory} from "react-router-dom";
import SuccessResult from "../../components/SuccessResult";
import ErrorResult from "../../components/ErrorResult";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {resetHomeForm} from "../../store/home";
import {clearPassengers} from "../../store/passengers";
import {clearFlights} from "../../store/selectedFlights";
import ReservationService from "../../actions/reservation.service";

const Result = (props: any) => {

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const env = process.env.REACT_APP_ENVIRONMENT;

  const reservationId = getQueryParameterByName("reservationId");
  const status = getQueryParameterByName("status");

  const passengers = useSelector((state: AppState) => state.passengers.passengers);

  const [isManualPaymentShown, setIsManualPaymentShown] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState<number>();
  const [debt, setDebt] = useState<string>();

  useEffect(() => {
    const reservationId = getQueryParameterByName("reservationId");

    ReservationService.getReservationById(reservationId).then((res) => {
      if (res.paymentMethod.name === 'Manual') {
        setIsManualPaymentShown(true);
        setConfirmationCode(res.confirmationCode);
        setDebt(`${res.debt} ${res.currency.isoCode}`);
      }

      if (res.source === 'skyscanner' || res.source === 'skyscanner_mob') {
        if (env === 'PROD') {
          // Initialise SAT with your Tracking Source ID
          (window as any).sat('init', 'SAT-959765-1');

          // Send a 'conversion' event
          (window as any).sat('send', 'conversion', {
            bookingReference: res.confirmationCode
          });
        }
      }
    }).catch(err => {
      console.log(err);
    })
  }, []);

  const handleHomeClick = () => {
    dispatch(resetHomeForm());
    dispatch(clearPassengers());
    dispatch(clearFlights());
    history.push("/");
  }

  const handleBookingDetailsClick = () => {
    dispatch(resetHomeForm());
    dispatch(clearPassengers());
    dispatch(clearFlights());
    history.push("/bookings/" + reservationId);
  }

  const localStorageData = JSON.parse((window as any).localStorage.getItem("pr_jet_home_data")) || null;

  const handleRetryClick = () => {
    if (!localStorageData || !localStorageData.dates[0]) {
      history.push({pathname: '/'})
    } else {
      const departureAirport = localStorageData?.fromAirport;
      const arrivalAirport = localStorageData?.toAirport;
      const departureDate = localStorageData?.dates[0];
      const returnDate = localStorageData?.dates[1];
      const adults = localStorageData?.adults;
      const children = localStorageData?.children;
      const infants = localStorageData?.infants;
      history.push({
        pathname: '/booking/overview',
        search: `?from=${departureAirport}&to=${arrivalAirport}&departure=${departureDate}${returnDate ? "&return=" + returnDate : ""}&adults=${adults}${children ? "&children=" + children : ""}${infants ? "&infants=" + infants : ""}`
      });
    }
  }

  return (
      <div>
        <div className="book-flight-container">
          <div className="book-flight-content">
            {status === "APPROVED" ?
                <SuccessResult
                    onHomeButtonClick={handleHomeClick}
                    onBookingDetailsButtonClick={handleBookingDetailsClick}
                    isManualPaymentShown={isManualPaymentShown}
                    confirmationCode={confirmationCode}
                    debt={debt}
                />
                : <ErrorResult
                    onHomeButtonClick={handleHomeClick}
                    onRetryButtonClick={handleRetryClick}
                />}
          </div>
        </div>
      </div>

  );
}


export default Result;
